import * as TrackersRepository from '../data/TrackersRepository';
import { AxiosResponse } from 'axios';
import * as moment from 'moment';

export const getAllCloseDates = async (): Promise<AxiosResponse<IGetAllCloseDatesResponse>> => {
    const result = await TrackersRepository.getAllCloseDates();
    const response = { ...result };
    result.data.closeDates = result.data.closeDates.map(w => moment(w).format("MM/DD/YYYY"));

    return response;
}

export const getAllTrackerUsers = async (): Promise<ITrackerUserResponse> => {
    const response = await TrackersRepository.getAllTrackerUsers()
    return response.data;
}

export const getTrackers = async (ledgerIds: string): Promise<ITrackerLedgerItemResponse> => {
    const response = await TrackersRepository.getTrackers(ledgerIds)
    response.data.ledgers = response.data.ledgers.map(w => {
        // this is needed so the kendo grid can format dates
        w.createDate = moment(w.createDate).format("MM/DD/YYYY");
        return w;
    });

    return response.data;
}

export const getTrackerHistories = async (ledgerId: number): Promise<IGetTrackerHistoriesResponse> => {
    const response = await TrackersRepository.getTrackerHistories(ledgerId);
    return response.data;
}

export const updateLedgerStatus = async (ledgerId: number, ledgerStatusId: number, comments: string): Promise<AxiosResponse> => {
    const response = await TrackersRepository.putTracker(ledgerId, { ledgerStatusId, statusComment: comments })
    return response;
}

export const updateLedgerHistoryComment = async (ledgerHistoryId: number, comments: string): Promise<AxiosResponse>  => {
    const response = await TrackersRepository.putLedgerHistoryComment({ ledgerHistoryId: ledgerHistoryId, statusComment: comments })
    return response;
}

export const batchUpdateLedgerStatus = async (ledgerIds: string, ledgerStatusId: number, comments: string): Promise<AxiosResponse> => {
    const ids = ledgerIds.split(',')
    const ledgers = ids.map(i => <ITrackerLedgerItem> { id: parseInt(i), ledgerStatusId, statusComment: comments })
    const response = await TrackersRepository.putTrackers(ledgers)
    return response;
}
