import * as ExpenseGroupRepository from '../data/ExpenseGroupRepository';
import { TrackerLedgerStatus } from '../common/enum/TrackerLedgerStatus';
import * as moment from 'moment';
const fileDownload = require('js-file-download');

export const getAllTrackersByStatusIds = async (filterState: IFilterState, 
    showPending: boolean, showProcessed: boolean, showCancelled: boolean, showRejected: boolean,
    viewAllTrackers: boolean = undefined, closeDateBegin: Date | null, closeDateEnd: Date | null) : Promise<any> => {
    
    let statusIds = [];
   
    if(showPending){
        statusIds.push(TrackerLedgerStatus.PendingReview);
        statusIds.push(TrackerLedgerStatus.ReadyForPayroll);
    }
    if (showProcessed) {
        statusIds.push(TrackerLedgerStatus.Processed);
    }
    if (showCancelled) {
        statusIds.push(TrackerLedgerStatus.Cancelled);
    }
    if (showRejected) {
        statusIds.push(TrackerLedgerStatus.Rejected);
    }  

    const response = await ExpenseGroupRepository.getAllTrackersByStatusIds(statusIds, 
        filterState, viewAllTrackers, closeDateBegin, closeDateEnd);
    
    let data = [];

    if(response.data.error == false){
        data = response.data.results.map((w: { closeDate: moment.MomentInput; }) => {
            // this is needed so the kendo grid can format dates
            w.closeDate = moment(w.closeDate).format("MM/DD/YYYY");
            return w;
        });
    }

    return {
        data,
        totals: response.data.expenseGroupTotals,
        count: response.data.count,
        error: response.data.error,
        errorMsg: response.data.errorMsg
    };
}

export const downloadExcelFile = async (filterState: IFilterState, 
    showPending: boolean, showProcessed: boolean, showCancelled: boolean, showRejected: boolean,
    viewAllTrackers: boolean = undefined, closeDateBegin: Date | null, closeDateEnd: Date | null) : Promise<any> => {
    
    let statusIds = [];
    
    if(showPending){
        statusIds.push(TrackerLedgerStatus.PendingReview);
        statusIds.push(TrackerLedgerStatus.ReadyForPayroll);
    }
    if (showProcessed) {
        statusIds.push(TrackerLedgerStatus.Processed);
    }
    if (showCancelled) {
        statusIds.push(TrackerLedgerStatus.Cancelled);
    }
    if (showRejected) {
        statusIds.push(TrackerLedgerStatus.Rejected);
    }

    const response = await ExpenseGroupRepository.getExcelFileExport(statusIds, filterState, viewAllTrackers, closeDateBegin, closeDateEnd);
    const mime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    fileDownload(response.data, 'TrackersExport.xlsx', mime)
}
