/// <reference path="../../../../common-types.d.ts" />
/// <reference path="../../../../interfaces.d.ts" />
/// <reference path="../../../../types.d.ts" />
/// <reference path="../../../../data/data-models.d.ts" />
import * as React from 'react';
import { AppContext } from '../../../AppStateProvider';
import { getClaimDetails } from '../../../../data/ClaimRepository';

interface ILedgersGridClassificationCellProps{
    dataItem: any,
    setError: (error: boolean, errorMsg: string) => void
}

export class LedgersGridClassificationCell extends React.Component<ILedgersGridClassificationCellProps> {

    static contextType = AppContext;
    context!: IReactContext<IAppState>;

    handleClick = async (e) => {
        e.preventDefault();
        const { claimId, userId } = this.props.dataItem;
        const response = await getClaimDetails(claimId, userId);

        if(response.data.error == true)
        {
            this.props.setError(response.data.error, response.data.errorMsg);
            return;
        }

        const item = response.data.claim;

        this.context.set({
            claimDetailModal: {
                open: true,
                item,
            }
        })
    }

    render() {
        const { billingCodeClassificationName, billingCodeClassificationId, claimDetailDisplayable } = this.props.dataItem;
        if (billingCodeClassificationId === 6 && claimDetailDisplayable) {
            return (
                <td>
                    <a className="grid-link" href="" onClick={this.handleClick}>{billingCodeClassificationName}</a>
                </td>
            );
        }
        
        return (
            <td>
                {billingCodeClassificationName}
            </td>
        );
    }
}