/// <reference path="../../../../common-types.d.ts" />
/// <reference path="../../../../interfaces.d.ts" />
/// <reference path="../../../../types.d.ts" />
/// <reference path="../../../../data/data-models.d.ts" />
import * as React from 'react';
import { AppContext } from '../../../AppStateProvider';
import { Wrapper } from '../../../shared-components/wrapper/Wrapper';
import { TrackerLedgerStatus } from '../../../../common/enum/TrackerLedgerStatus';
import { BillingCodeClassification } from '../../../../common/enum/BillingCodeClassification';
import { batchUpdateLedgerStatus } from '../../../../service/TrackersService';
import { TrackerRole } from '../../../../common/enum/TrackerRole';
import { loadTrackersGrid } from '../helpers';
import { StatusCommentsModal } from '../../../shared-components/status-comments/StatusCommentsModal';
import { ConfirmModal } from '../../../shared-components/confirm/ConfirmModal';

interface ILedgersGridActionCellProps{
    dataItem: any;
    viewAllTrackers: boolean;
    setError: (error: boolean, errorMsg: string) => void;
}

export class LedgersGridActionCell extends React.Component<ILedgersGridActionCellProps> {

    static contextType = AppContext;
    context!: IReactContext<IAppState>;

    handleUpdateLedgerStatus = async (ledgerStatusId: number, comments?: string) => {
        let ledgerIds  = this.context.state.statusCommentsModal.associatedLedgerIds != "" ?  this.context.state.statusCommentsModal.associatedLedgerIds :  this.props.dataItem.ledgerIds;

        this.context.set(
            {
                confirmModal: {open: false, comments: ''},
                statusCommentsModal: {
                    open: false,
                    associatedLedgerIds: "",
                    comments: ""
                },
                trackers: { loadingLedgers: true }
            },
            );
        
        const success = await batchUpdateLedgerStatus(ledgerIds, ledgerStatusId, comments);
        if (success.data.error == true) {
            this.props.setError(success.data.error, success.data.errorMsg);

            return;
        }

        var response = await loadTrackersGrid(this.context, this.props.viewAllTrackers);

        if(response.error == true){
            this.props.setError(response.error, response.errorMsg);

            return;
        }
    }
    

    confirmReject = async (ledgerStatusId: number, comments: string) =>{

        this.context.set({
            statusCommentsModal: {
                open: false,
                associatedLedgerIds: this.context.state.statusCommentsModal.associatedLedgerIds,
                comments: comments
            },
            confirmModal: {
                open: true,
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
                title: 'Reject Trackers',
                message: 'Are you sure you want to reject all the trackers in the group?',
                ledgerStatusId: ledgerStatusId,
                comments: comments,
                associatedLedgerIds: this.context.state.statusCommentsModal.associatedLedgerIds,
                groupStatusChange: true
            }
        })
    }

    confirmCancel = async (ledgerStatusId: number, comments: string) =>{
        this.context.set({
            statusCommentsModal: {
                open: false,
                associatedLedgerIds: this.context.state.statusCommentsModal.associatedLedgerIds,
                comments: comments
            },
            confirmModal: {
                open: true,
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
                title: 'Cancel Trackers',
                message: 'Are you sure you want to cancel all the trackers in the group?',
                ledgerStatusId: ledgerStatusId,
                comments: comments,
                associatedLedgerIds: this.context.state.statusCommentsModal.associatedLedgerIds,
                groupStatusChange: true
           }
        })
    }

    confirmModal = async (ledgerStatusId: number, comments: string) => {
        await this.handleUpdateLedgerStatus(ledgerStatusId, comments);
    }

    closeConfirmModal = () => {
        this.context.set({
            statusCommentsModal: { 
                open: true, 
                associatedLedgerIds: this.context.state.statusCommentsModal.associatedLedgerIds,
                comments: this.context.state.statusCommentsModal.comments 
            },
            confirmModal: {open: false, comments: ''}
        });
    }
    
    submitStatusComments = async (comments: string) => {
        
        if(comments == null || comments.length < 1){
            this.context.set({statusCommentsModal: {open: true, hasError: true, commentsError: "Comments are required."}});
        }
        else{
            let ledgerStatusId = this.context.state.statusCommentsModal.ledgerStatusId;

            if(ledgerStatusId == TrackerLedgerStatus.Rejected){
                await this.confirmReject(ledgerStatusId, comments);
            }
            else{
                await this.confirmCancel(ledgerStatusId, comments);
            }
        }
    }

    closeStatusModal = () => {
        this.context.set({statusCommentsModal: {open: false, associatedLedgerIds: ""}});
    }

    handleReject = (ledgerIds: string) => {
        this.context.set({
            statusCommentsModal: {
                open: true,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Submit',
                title: 'Reject Trackers',
                commentsTitle: "Rejection Comments",
                ledgerStatusId: TrackerLedgerStatus.Rejected,
                hasError: false,
                commentsError: '',
                comments: '',
                associatedLedgerIds: ledgerIds,
                groupStatusChange: true
            },
            confirmModal: {comments: ''}
        });

    }

    handleCancel = (ledgerIds: string) => {
        this.context.set({
            statusCommentsModal: {
                open: true,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Submit',
                title: 'Cancel Trackers',
                commentsTitle: "Cancellation  Comments",
                ledgerStatusId: TrackerLedgerStatus.Cancelled,
                hasError: false,
                commentsError: '',
                comments: '',
                associatedLedgerIds: ledgerIds,
                groupStatusChange: true
            },
            confirmModal: {comments: ''}
        });
    }

    renderApproveButton = () => {
        return (
            <button onClick={() => this.handleUpdateLedgerStatus(TrackerLedgerStatus.ReadyForPayroll)} className="btn btn-success btn-sm action-button btn-margin">Approve</button>
        )
    }

    renderProcessButton = () => {
        return (
            <button onClick={() => this.handleUpdateLedgerStatus(TrackerLedgerStatus.Processed)} className="btn btn-primary btn-sm action-button btn-margin">Process</button>
        )
    }

    renderRejectButton = (ledgerIds: string) => {
        return (
            <button onClick={() => this.handleReject(ledgerIds)} className="btn btn-danger btn-sm action-button btn-margin">Reject</button>
        )
    }

    renderCancelButton = (ledgerIds: string) => {
        return (
            <button onClick={() => this.handleCancel(ledgerIds)} className="btn btn-danger btn-sm action-button btn-margin">Cancel</button>
        )
    }

    render() {
        const { userId, ledgerStatusId, billingCodeClassificationId, ledgerIds, hideFromCTS } = this.props.dataItem;
        const { id, trackerRole, permissionToProcessTrackers } = this.context.state.user;
        
        return (
            <td>
                <Wrapper>
                    { (trackerRole === TrackerRole.Admin || (trackerRole === TrackerRole.Reviewer && userId !== id)) 
                        && ledgerStatusId === TrackerLedgerStatus.PendingReview 
                        && this.renderApproveButton() }

                    { permissionToProcessTrackers 
                        && ledgerStatusId === TrackerLedgerStatus.ReadyForPayroll 
                        && this.renderProcessButton() }

                    { (trackerRole === TrackerRole.Admin || (trackerRole === TrackerRole.Reviewer && userId !== id))
                        && (ledgerStatusId === TrackerLedgerStatus.PendingReview ||ledgerStatusId === TrackerLedgerStatus.ReadyForPayroll)
                        && this.renderRejectButton(ledgerIds) }

                    { (userId === id) 
                        && (ledgerStatusId === TrackerLedgerStatus.PendingReview ||ledgerStatusId === TrackerLedgerStatus.ReadyForPayroll)
                        && hideFromCTS
                        && this.renderCancelButton(ledgerIds) }
                </Wrapper>
                {
                    this.context.state.statusCommentsModal.open && 
                    <StatusCommentsModal comments={this.context.state.statusCommentsModal.comments} submitModal={this.submitStatusComments} closeModal={this.closeStatusModal} ledgerIds={ledgerIds} groupStatusChange={true}/>
                }
                {
                    this.context.state.confirmModal.open && 
                    <ConfirmModal confirmModal={this.confirmModal} closeModal={this.closeConfirmModal} ledgerIds={ledgerIds} groupStatusChange={true}/>
                }
            </td>
        );
    }
}