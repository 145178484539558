/// <reference path="../../../../common-types.d.ts" />
/// <reference path="../../../../interfaces.d.ts" />
/// <reference path="../../../../types.d.ts" />
/// <reference path="../../../../data/data-models.d.ts" />
import * as React from 'react';
import { AppContext } from '../../../AppStateProvider';
import { getClaimDetails } from '../../../../data/ClaimRepository';
import { getTrackerHistories } from '../../../../service/TrackersService';

interface ILedgersGridRowDetailStatusCellProps{
    dataItem: any,
    setError: (error: boolean, errorMsg: string) => void
}

export class LedgersGridRowDetailStatusCell extends React.Component<ILedgersGridRowDetailStatusCellProps> {

    static contextType = AppContext;
    context!: IReactContext<IAppState>;

    handleClick = async (e) => {
        e.preventDefault();
        
        const { id, ledgerStatusName, claimNumber, billingCodeClassificationName, userFullName, closeDate } = this.props.dataItem;

        const getTrackerHistoriesResponse = await getTrackerHistories(id);

        if(getTrackerHistoriesResponse.error == true){
            this.props.setError(getTrackerHistoriesResponse.error, getTrackerHistoriesResponse.errorMsg);
            return;
        }

        this.context.set({
            ledgerHistoryModal: {
                open: true,
                ledgerId: id,
                claimNumber: claimNumber,
                billingCodeClassificationName: billingCodeClassificationName,
                ledgerStatusName: ledgerStatusName,
                userFullName: userFullName,
                closeDate: closeDate,
                grid:{
                    data: getTrackerHistoriesResponse.ledgerHistories
                }
            }
        })
    }

    render() {
        
        const { ledgerStatusName } = this.props.dataItem;
        return (
            <td>
                <a className="grid-link" href="" onClick={this.handleClick}>{ledgerStatusName}</a>
            </td>
        );
    }
}