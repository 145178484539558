/// <reference path="../../../../common-types.d.ts" />
/// <reference path="../../../../interfaces.d.ts" />
/// <reference path="../../../../types.d.ts" />
/// <reference path="../../../../data/data-models.d.ts" />
import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { AppContext } from '../../../AppStateProvider';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Grid, GridColumn, GridDetailRow } from '@progress/kendo-react-grid';
import { LedgerHistoryCommentCell } from './LedgerHistoryCommentCell';
import { updateLedgerHistoryComment } from '../../../../service/TrackersService';
import { debug } from 'webpack';
import { getTrackerHistories } from '../../../../service/TrackersService';

declare interface ILedgerHistoryModalProps {
    setError: (error: boolean, errorMsg: string) => void
}

declare interface ILedgerHistoryModalState {
    editLedgerHistoryId: number,
    editComment: string
}

export class LedgerHistoryModal extends React.Component<ILedgerHistoryModalProps, ILedgerHistoryModalState> {
    static contextType = AppContext;
    context!: IReactContext<IAppState>;

    state = {
        editLedgerHistoryId: 0,
        editComment: ""
    }

    closeModal = () => {

        this.setState({editLedgerHistoryId: 0, editComment: ""});

        this.context.set({
            ledgerHistoryModal: { 
                open: false,
                statusMessage: "",
                editStatusComment: "",
                editLedgerHistoryId: 0,
                commentCellClicked: false,
                hasError: false
            }
        })
    }

    handleSave = async () => {

        var ledgerHistoryId = this.state.editLedgerHistoryId;
        var comment = this.state.editComment;

        if(ledgerHistoryId > 0){
            const success = await updateLedgerHistoryComment(ledgerHistoryId, comment);
            if (success.data.error == true) {
                this.props.setError(success.data.error, success.data.errorMsg);
                return;
            }
            else{
                const data = await getTrackerHistories(this.context.state.ledgerHistoryModal.ledgerId);

                if(data.error == true){
                    this.props.setError(data.error, data.errorMsg);
                    return;
                }

                this.context.set({
                    ledgerHistoryModal: {
                        statusMessage:"Your comment was saved successfully.",
                        hasError: false,
                        grid:{
                            data: data.ledgerHistories
                        }
                    }
                });
            }
        }
        else{
            this.context.set({
                ledgerHistoryModal: {
                    hasError: true,
                    statusMessage: "No comment has been selected. Please select a comment to add/change."
                }
            });
        }
    }

    handleCommentClick = (ledgerHistoryId: number, comment: string) => {
        this.state.editLedgerHistoryId = ledgerHistoryId;
        this.state.editComment = comment != null ? comment : "";

        this.context.set({
            ledgerHistoryModal: 
            { 
                editLedgerHistoryId: ledgerHistoryId,
                editStatusComment: this.state.editComment,
                statusMessage: "",
                hasError: false,
                commentCellClicked: true
             }
            });
    }

    render() {
        const { ledgerHistoryModal } = this.context.state;

        let userFullNameText = "";
        let claimNumberText = "";
        let classificationNameText = "";
        let ledgerStatusNameText = "";
        let closeDateText = "";

        if(ledgerHistoryModal.userFullName != null && ledgerHistoryModal.userFullName.length > 0)
        {
            userFullNameText = " - " + ledgerHistoryModal.userFullName;
        }

        if(ledgerHistoryModal.claimNumber != null && ledgerHistoryModal.claimNumber.length > 0)
        {
            claimNumberText = " - " + ledgerHistoryModal.claimNumber;
        }

        if(ledgerHistoryModal.billingCodeClassificationName != null && ledgerHistoryModal.billingCodeClassificationName.length > 0)
        {
            classificationNameText = " - " + ledgerHistoryModal.billingCodeClassificationName;
        }

        if(ledgerHistoryModal.ledgerStatusName != null && ledgerHistoryModal.ledgerStatusName.length > 0)
        {
            ledgerStatusNameText = " - " + ledgerHistoryModal.ledgerStatusName;
        }

        if(ledgerHistoryModal.closeDate != null && ledgerHistoryModal.closeDate.length > 0)
        {
            closeDateText = " - " + ledgerHistoryModal.closeDate;
        }

        return (
            <div >
                { ledgerHistoryModal.open &&
                    <Dialog title={"Change Log" + userFullNameText + claimNumberText + classificationNameText + ledgerStatusNameText + closeDateText} onClose={this.closeModal}>
                        
                         <Grid className="detail-grid" data={this.context.state.ledgerHistoryModal.grid.data}>
                            <GridColumn field="updateDateTime" title="Date/Time" groupable={false} sortable={false} filterable={false} width={180} />
                            <GridColumn field="ledgerStatusName" title="Status" groupable={false} sortable={false} filterable={false} width={160}/>
                            <GridColumn field="amount" format="{0:c}" title="$Submitted" groupable={false} sortable={false} filterable={false} width={100}/>
                            <GridColumn field="description" title="Description" groupable={false} sortable={false} filterable={false} width={200} />
                            <GridColumn field="statusComment"  title="Comment" groupable={false} sortable={false} filterable={false} width={300}
                                cell={(props) => <LedgerHistoryCommentCell handleClick={this.handleCommentClick} statusComment={props.dataItem.statusComment} ledgerHistoryId={props.dataItem.id} clicked={ledgerHistoryModal.commentCellClicked}/>}
                            />
                            <GridColumn field="userNameFull" title="User" groupable={false} sortable={false} filterable={false} width={200} />
                        </Grid>
                        { ledgerHistoryModal.commentCellClicked &&
                            <div>
                                <br />
                                <h6 style={{color: 'black'}}>Comment</h6>
                                <textarea   
                                            value={this.state.editComment}
                                            onChange={e => this.setState({editComment: e.target.value})}
                                            rows={5}
                                            cols={100}
                                        />
                            </div>
                        }
                        { ledgerHistoryModal.statusMessage != null && ledgerHistoryModal.statusMessage.length > 0 && 
                            <div style={{ color: ledgerHistoryModal.hasError ? 'red' : '#28a745'}}>
                                {ledgerHistoryModal.statusMessage}
                            </div>
                        }
                        <DialogActionsBar>
                            <button className="k-button" onClick={this.closeModal}>Close</button>
                            <button className="k-button" onClick={this.handleSave}>Save Comment</button>
                        </DialogActionsBar>
                    </Dialog>
                }     
            </div>
        )
    }
}