/// <reference path="../../../../common-types.d.ts" />
/// <reference path="../../../../interfaces.d.ts" />
/// <reference path="../../../../types.d.ts" />
/// <reference path="../../../../data/data-models.d.ts" />
import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { AppContext } from '../../../AppStateProvider';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Grid, GridColumn, GridDetailRow } from '@progress/kendo-react-grid';
import { LedgerHistoryCommentCell } from './LedgerHistoryCommentCell';
import { updateLedgerHistoryComment } from '../../../../service/TrackersService';
import { debug } from 'webpack';
import { getTrackerHistories } from '../../../../service/TrackersService';

declare interface IErrorModalProps {
    Error: boolean,
    ErrorMsg: string,
    closeModal: () => void;
}

export class ErrorModal extends React.Component<IErrorModalProps> {
    static contextType = AppContext;
    context!: IReactContext<IAppState>;

    closeModal = () => {
        this.props.closeModal();
    }

    render() {

        let dateNow = new Date();

        return (
            <div >
                { this.props.Error &&
                    <Dialog title={"Error Alert"} onClose={this.closeModal} className="errorModal">
                        <div>
                            An unexpected error has occurred. If the issue continues, please send a screenshot of the error to PLC.
                        </div>
                        <div>
                            Date and Time: {dateNow.toUTCString().replace("GMT", "")} UTC
                        </div>
                        { this.props.ErrorMsg != null && this.props.ErrorMsg.length > 0 && 
                            <div>
                                Error Message: <br />
                                {this.props.ErrorMsg}
                            </div>
                        }
                        <DialogActionsBar>
                            <button className="k-button" onClick={this.closeModal}>Close</button>
                        </DialogActionsBar>
                    </Dialog>
                }     
            </div>
        )
    }
}