/// <reference path="../../../../common-types.d.ts" />
/// <reference path="../../../../interfaces.d.ts" />
import * as React from 'react';
import { AppContext } from '../../../AppStateProvider';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Wrapper } from '../../../shared-components/wrapper/Wrapper';

interface ILedgersGridBulkProcessButtonProps {
    onClick: () => void;
}

export class LedgersGridBulkProcessButton extends React.Component<ILedgersGridBulkProcessButtonProps> {

    static contextType = AppContext;
    context!: IReactContext<IAppState>;

    onClick = () => {
        this.props.onClick();
    }

    render() {
        let data = this.context.state.trackers.grid.data;

        let checkedForBulkProcess = false;

        data.forEach(element => {
            if(element.checkedForBulkProcess){
                checkedForBulkProcess = true;
            }
        });

        return (
            <Wrapper>
                {checkedForBulkProcess &&
                    <div className="k-filtercell" style={{marginLeft: "5px"}}>
                        <button onClick={() => this.onClick()} className="btn btn-primary btn-sm action-button btn-margin">Process</button>
                    </div>
                }
            </Wrapper>
        );
    }
}