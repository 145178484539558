import * as React from 'react';
import { RegisterUnauthorizedAction } from '../../../common/configuration/repositories';
import { AppContext } from '../../AppStateProvider';
import { getAuthStatus } from '../../../data/IdentityRepository';

export class OnAppStart extends React.Component {

    static contextType = AppContext;
    context!: IReactContext<IAppState>;

    // Register an actions not using state in the constructor
    constructor(props: any, context: IReactContext<IAppState>) {
        super(props);
        this.context = context;

        // register action that will happen when/if 401 is rnpmeturned
        RegisterUnauthorizedAction();
    }

    // Register any actions using state in componentDidMount
    async componentDidMount() {
        const response = await getAuthStatus();
        
        if(!response.data.trackerUser.permissionToViewTrackers){
            window.location.replace(appSettings.CtsUrl + "/Dashboard.aspx");
        }

        const user = {
            ...response.data.trackerUser,
            authenticated: response.status === 200
        }

        this.context.set({ user });
    }

    render() {
        return null;
    }
}