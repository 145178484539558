/// <reference path="../../../interfaces.d.ts"/>
/// <reference path="../../../common-types.d.ts"/>
import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { AppContext } from '../../AppStateProvider';
import { OverlayLoader } from '../../shared-components/overlay-loader/OverlayLoader';
import { LedgersGrid } from './sub-components/LedgersGrid';
import { AddTrackerModal } from './sub-components/AddTracker';
import { EditTrackerModal } from './sub-components/EditTracker';
import { ClaimDetail } from './sub-components/ClaimDetail';
import { LedgerHistoryModal } from './sub-components/LedgerHistoryModal';
import { EditLedgerCloseDateModal } from './sub-components/EditLedgerCloseDateModal';
import { downloadExcelFile, getAllTrackersByStatusIds } from '../../../service/ExpenseGroupService'
import { loadTrackersGrid} from './helpers';
import { TrackerRole } from '../../../common/enum/TrackerRole';
import { getTrackerUsers } from '../../../data/TrackersRepository';
import { getTestError } from '../../../data/ErrorTestRepository';
import './Trackers.scss';
import { ErrorModal } from './sub-components/ErrorModal';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import { close } from 'fs';

export class Trackers extends React.Component {

    static contextType = AppContext;
    context!: IReactContext<IAppState>;
    state = {
        addTrackerModalOpen: false,
        dueDate: undefined,
        paidDate: undefined,
        editLedgerCloseDateModalOpen: false,
        editLedgerCloseDateLedgerId: undefined,
        ledgerCloseDate: undefined,
        closeDateList: [],
        viewAllTrackers: undefined,
        error: false,
        errorMsg: undefined
    }

    async componentDidMount () {

        const today = new Date()
        const day = today.getDate()
        const month = today.getMonth() + 1
        const year = today.getFullYear()
        const dueDate = this.getDueDate(day, month, year)
        const paidDate = this.getPaidDate(day, month, year)

        this.setState({
            ...this.state,
            dueDate,
            paidDate,
        })
    }

    getDueDate = (day, month, year) => {
        if (day <= 10) {
            return `${month}/${10}/${year}`
        }
        if (day <= 25) {
            return `${month}/${25}/${year}`
        }
        return this.getNextMonthsDate(10, month, year)
    }

    getPaidDate = (day, month, year) => {
        if (day <= 10) {
            return `${month}/${15}/${year}`
        }
        if (day <= 25) {
            return this.getNextMonthsDate(1, month, year)
        }
        return this.getNextMonthsDate(15, month, year)
    }

    getNextMonthsDate = (day, month, year) => {
        if (month === 12) {
            return `${1}/${day}/${year + 1}`
        }
        return `${month + 1}/${day}/${year}`
    }

    getDateString = (day, month, year) => {
        return `${month + 1}/${day}/${year}`
    }

    getOverlay = () => {
        if (this.context.state.trackers.loadingLedgers === true) {
            return (<OverlayLoader />);
        }

        return null;
    }

    handleAddTrackerClick = async () => {
        
        this.setState({ 
            addTrackerModalOpen: true
        })
    }

    handleTestErrorClick = async () => {
        var response = await getTestError();

        if(response.data.error == true){
            this.setError(response.data.error, response.data.errorMsg);
        }
    }

    handleCloseModal = () => {
        this.setState({ addTrackerModalOpen: false })
    }

    handleCloseEditModal = () => {
        this.context.set({ 
            editModal: { 
                open: false, 
                item: undefined
            } })
    }

    handleExportClick = async (e) => {

        const { grid } = this.context.state.trackers;
        const { filterState } = grid;
    
        const filterDescriptor = {
            ...filterState,
        };

        await downloadExcelFile(filterDescriptor, grid.showPending, grid.showProcessed, grid.showCancelled, grid.showRejected, this.state.viewAllTrackers, grid.closeDateBegin, grid.closeDateEnd);
    }

    handleRefreshClick = async () => {
        var response = await loadTrackersGrid(this.context, this.state.viewAllTrackers);

        if(response.error == true){
            this.setError(response.error, response.errorMsg);

            return;
        }
    }

    handleChange = (name) => async (event) => {
        const { checked } = event.target
        this.context.set({
            trackers: {
                loadingLedgers: true,
                grid: {
                    [name]: checked
                }
            }
        });

        const { grid } = this.context.state.trackers;
        const { filterState } = grid;
    
        const filterDescriptor = {
            ...filterState,
            take: grid.loadedCount < filterState.take ? filterState.take : grid.loadedCount, 
            skip: 0 
        };
        
        let showPending, showProcessed, showCancelled, showRejected, viewAllTrackers, closeDateBegin, closeDateEnd;
        showPending = grid.showPending;
        showProcessed = grid.showProcessed;
        showCancelled = grid.showCancelled;
        showRejected = grid.showRejected;
        closeDateBegin = grid.closeDateBegin;
        closeDateEnd = grid.closeDateEnd;

        viewAllTrackers = this.state.viewAllTrackers;

        var allowedUsers = this.context.state.user.allowedUsers;

        if(name ==='showPending'){
            showPending = event.target.checked;
        } else if (name === 'showProcessed') {
            showProcessed = event.target.checked;
        } else if (name === 'showCancelled') {
            showCancelled = event.target.checked;
        } else if (name === 'showRejected') {
            showRejected = event.target.checked;
        } else if (name === 'viewAllTrackers'){
            viewAllTrackers = event.target.checked;
        }

        var viewAllTrackerUsers = viewAllTrackers == true;

        const getTrackerUsersPromise = getTrackerUsers(viewAllTrackerUsers);
        
        const trackerUsersResponse = await getTrackerUsersPromise;

        if(trackerUsersResponse.data.error == true){
            this.setError(trackerUsersResponse.data.error, trackerUsersResponse.data.errorMsg);
            
            return;
        }

        allowedUsers = trackerUsersResponse.data.trackerUsers;

        this.setState({
            ...this.state,
            viewAllTrackers: viewAllTrackers
        });

        const { data, totals, count, error, errorMsg} = await getAllTrackersByStatusIds(filterDescriptor, showPending, showProcessed, showCancelled, showRejected, viewAllTrackers, closeDateBegin, closeDateEnd);

        if(error == false) {

            this.context.set({
                user:{
                    allowedUsers: allowedUsers
                },
                trackers: {
                    grid: {
                        [name]: checked,
                        data,
                        total: count,
                    },
                    loadingLedgers: false,
                    totalAmountTotal: totals.totalAmountTotal,
                    amountPaidTotal: totals.amountPaidTotal,
                    amountDueTotal: totals.amountDueTotal
                },
            });
        }

        else{
            this.setError(error, errorMsg);
        }
    }

    handleDateChange = (name) => async (event) => {

        if (event.nativeEvent instanceof InputEvent) {
            if (event.nativeEvent.data !== "enter") {
                this.context.set({
                    trackers: {
                        grid: {
                            [name]: null
                        }
                    }
                });
                return;
            }
        }

        this.context.set({
            trackers: {
                loadingLedgers: true,
                grid: {
                    [name]: event.target.state.value
                }
            }
        });

        const { grid } = this.context.state.trackers;
        const { filterState } = grid;
    
        const filterDescriptor = {
            ...filterState,
            take: grid.loadedCount < filterState.take ? filterState.take : grid.loadedCount, 
            skip: 0 
        };
        
        let showPending, showProcessed, showCancelled, showRejected, viewAllTrackers, closeDateBegin, closeDateEnd;
        showPending = grid.showPending;
        showProcessed = grid.showProcessed;
        showCancelled = grid.showCancelled;
        showRejected = grid.showRejected;
        closeDateBegin = grid.closeDateBegin;
        closeDateEnd = grid.closeDateEnd;

        viewAllTrackers = this.state.viewAllTrackers;

        var allowedUsers = this.context.state.user.allowedUsers;

        if (name === 'closeDateBegin'){
            closeDateBegin = event.value;
        } else if (name === 'closeDateEnd'){
            closeDateEnd = event.value;
        }

        var viewAllTrackerUsers = viewAllTrackers == true;

        const getTrackerUsersPromise = getTrackerUsers(viewAllTrackerUsers);
        
        const trackerUsersResponse = await getTrackerUsersPromise;

        if(trackerUsersResponse.data.error == true){
            this.setError(trackerUsersResponse.data.error, trackerUsersResponse.data.errorMsg);
            
            return;
        }

        allowedUsers = trackerUsersResponse.data.trackerUsers;

        this.setState({
            ...this.state,
            viewAllTrackers: viewAllTrackers
        });

        const { data, totals, count, error, errorMsg} = await getAllTrackersByStatusIds(filterDescriptor, showPending, showProcessed, showCancelled, showRejected, viewAllTrackers, closeDateBegin, closeDateEnd);

        if(error == false) {

            this.context.set({
                user:{
                    allowedUsers: allowedUsers
                },
                trackers: {
                    grid: {
                        [name]: event.value,
                        data,
                        total: count,
                    },
                    loadingLedgers: false,
                    totalAmountTotal: totals.totalAmountTotal,
                    amountPaidTotal: totals.amountPaidTotal,
                    amountDueTotal: totals.amountDueTotal
                },
            });
        }

        else{
            this.setError(error, errorMsg);
        }
    }

    openEditCloseDateModal = (ledgerId: string, closeDate: string) => {
        var currentPeriodCloseDate = new Date(this.state.dueDate);
        var closeDateList = [];

        var currentPeriod = new Date(currentPeriodCloseDate);

        if(currentPeriodCloseDate.getDate() == 10){
            //current close date period is on the 10th
            var previousDate = new Date(currentPeriodCloseDate.setMonth(currentPeriodCloseDate.getMonth()-1));
            closeDateList.push(this.getDateString(previousDate.getDate(), previousDate.getMonth(), previousDate.getFullYear()));

            previousDate = new Date(previousDate.setDate(previousDate.getDate() + 15));

            closeDateList.push(this.getDateString(previousDate.getDate(), previousDate.getMonth(), previousDate.getFullYear()));
            closeDateList.push(this.getDateString(currentPeriod.getDate(), currentPeriod.getMonth(), currentPeriod.getFullYear()));

            var futureDate = new Date(currentPeriod.setDate(currentPeriod.getDate() + 15));

            closeDateList.push(this.getDateString(futureDate.getDate(), futureDate.getMonth(), futureDate.getFullYear()));
        }
        else{
            //current close date period is on the 25th
            var previousDate = new Date(currentPeriodCloseDate.setMonth(currentPeriodCloseDate.getMonth()-1));
            closeDateList.push(this.getDateString(previousDate.getDate(), previousDate.getMonth(), previousDate.getFullYear()));

            previousDate = new Date(currentPeriodCloseDate.setMonth(currentPeriodCloseDate.getMonth() + 1));
            previousDate = new Date(currentPeriodCloseDate.setDate(currentPeriodCloseDate.getDate() - 15));

            closeDateList.push(this.getDateString(previousDate.getDate(), previousDate.getMonth(), previousDate.getFullYear()));
            closeDateList.push(this.getDateString(currentPeriod.getDate(), currentPeriod.getMonth(), currentPeriod.getFullYear()));

            var futureDate = new Date(currentPeriod.setMonth(currentPeriod.getMonth() + 1));

            futureDate = new Date(futureDate.setDate(currentPeriod.getDate() - 15));
            closeDateList.push(this.getDateString(futureDate.getDate(), futureDate.getMonth(), futureDate.getFullYear()));
        }

        this.setState({
            ...this.state,
            editLedgerCloseDateModalOpen: true,
            editLedgerCloseDateLedgerId: ledgerId,
            ledgerCloseDate: closeDate,
            closeDateList: closeDateList
        });
    }

    closeEditCloseDateModal = async (saved: boolean) => {
        this.setState({
            ...this.state,
            editLedgerCloseDateModalOpen: false,
            editLedgerCloseDateLedgerId: undefined,
            ledgerCloseDate: undefined,
            closeDateList: []
        });

        if(saved == true){
            //perform reset of entire page because a ledger changed groups
            var response = await loadTrackersGrid(this.context, this.state.viewAllTrackers);

            if(response.error == true){
                this.setError(response.error, response.errorMsg);
                return;
            }
        }
    }

    closeErrorModal = () => {
        this.setState({
            error: false,
            errorMsg: undefined
        });
    }

    setError = (error: boolean, errorMsg: string) => {
        this.setState({
            error: error,
            errorMsg: errorMsg
        });

        this.context.set({ trackers: { loadingLedgers: false }});
    }

    render() {
        const { isLead, isSuperAdmin } = this.context.state.user;

        return (
            <Container fluid={true} className="trackers-component">
                <style dangerouslySetInnerHTML={{__html: `
                .k-window-title{
                    line-height: 1.5 !important
                }

                .confirmModal > .k-overlay{
                    opacity: 0
                }

                input{
                    color: black !important;
                }
                `}}></style>
                <AddTrackerModal isOpen={this.state.addTrackerModalOpen} closeModal={this.handleCloseModal} viewAllTrackers={this.state.viewAllTrackers} setError={this.setError} />
                { this.context.state.editModal.open &&
                    <EditTrackerModal isOpen={this.context.state.editModal.open} closeModal={this.handleCloseEditModal} viewAllTrackers={this.state.viewAllTrackers} setError={this.setError} />
                }
                
                <ClaimDetail />
                <LedgerHistoryModal setError={this.setError} />
                <ErrorModal Error={this.state.error} ErrorMsg={this.state.errorMsg} closeModal={this.closeErrorModal} />
                <EditLedgerCloseDateModal 
                    open={ this.state.editLedgerCloseDateModalOpen} 
                    ledgerId={this.state.editLedgerCloseDateLedgerId} 
                    ledgerCloseDate={this.state.ledgerCloseDate} 
                    closeDateList={this.state.closeDateList}
                    closeModal={this.closeEditCloseDateModal} 
                    setError={this.setError}/>
                <Row>
                    <Col sm={3}>
                        <label style={{ fontSize: '32px'}}>
                            {'Trackers'}
                        </label>
                    </Col>
                    <Col sm={9} style={{ paddingTop: '10px'}}>
                        <span>
                            <label>{'For the current pay period, trackers are due on'}&nbsp;</label>
                            <label style={{ fontWeight: 'bold' }}>{this.state.dueDate}</label>
                            <label>&nbsp;{'and will be paid on'}&nbsp;</label>
                            <label style={{ fontWeight: 'bold' }}>{this.state.paidDate}</label>
                        </span>
                    </Col>
                </Row>
                <Row className="grid-header-row">
                    {/* <Col sm={3}>
                        <label>{`Showing ${total} results`}</label>
                    </Col> */}
                    <Col sm={3}>
                        <label>
                            <input type="checkbox"
                                checked={this.context.state.trackers.grid.showPending}
                                onChange={this.handleChange('showPending')}
                            />
                            {'Show Pending Trackers'}
                        </label>
                        <br />
                        <label>
                            <input type="checkbox"
                                checked={this.context.state.trackers.grid.showProcessed}
                                onChange={this.handleChange('showProcessed')}
                            />
                            {'Show Processed Trackers'}
                        </label>
                    </Col>
                    <Col sm={3}>
                        <label>
                            <input type="checkbox"
                                checked={this.context.state.trackers.grid.showCancelled}
                                onChange={this.handleChange('showCancelled')}
                            />
                            {'Show Cancelled Trackers'}
                        </label>
                        <br />
                        <label>
                            <input type="checkbox"
                                checked={this.context.state.trackers.grid.showRejected}
                                onChange={this.handleChange('showRejected')}
                            />
                            {'Show Rejected Trackers'}
                        </label>
                    </Col>
                    <Col sm={3}>
                        <label>
                            <DatePicker value={this.context.state.trackers.grid.closeDateBegin} className={"datepicker"} title={"Closed Date: Start"} onChange={this.handleDateChange('closeDateBegin')} />
                            {'Close Date: Start'}
                        </label>
                        <br />
                        <label>
                            <DatePicker value={this.context.state.trackers.grid.closeDateEnd} className="datepicker" title="Closed Date: End"  onChange={this.handleDateChange('closeDateEnd')} />
                            {'Close Date: End'}
                        </label>
                    </Col>
                    <Col sm={3}>
                        {
                            isSuperAdmin && 
                            <button className="btn btn-sm btn-danger pull-right header-btn-margin" onClick={this.handleTestErrorClick}>Test Error</button>
                        }
                        <button className="btn btn-sm btn-secondary pull-right header-btn-margin" onClick={this.handleExportClick}>Export</button>
                        <button className="btn btn-sm btn-success pull-right header-btn-margin" onClick={this.handleRefreshClick}>Refresh</button>
                        <button className="btn btn-sm btn-primary pull-right " onClick={this.handleAddTrackerClick}>Add Tracker</button>
                    </Col>
                </Row>
                {   (isLead == true) &&
                
                    <Row className="grid-header-row">
                        <Col sm={2}>
                        <label>
                            <input type="checkbox"
                                checked={this.state.viewAllTrackers}
                                onChange={this.handleChange('viewAllTrackers')}
                            />
                            {'View All Trackers'}
                        </label>
                        </Col>
                    </Row>
                }
                <Row className="justify-content-md-center">
                    <Col>
                        <div id='trackersPage'>
                            {this.getOverlay()}
                            <LedgersGrid openEditCloseDateModal={this.openEditCloseDateModal} viewAllTrackers={this.state.viewAllTrackers} setError={this.setError}/>
                        </div>
                    </Col>
                </Row>
            </Container>);
    }
}