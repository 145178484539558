/// <reference path="../../../../common-types.d.ts" />
/// <reference path="../../../../interfaces.d.ts" />
/// <reference path="../../../../types.d.ts" />
/// <reference path="../../../../data/data-models.d.ts" />
import * as React from 'react';
import { AppContext } from '../../../AppStateProvider';
import { Wrapper } from '../../../shared-components/wrapper/Wrapper';

export class LedgersGridRowDetailAttachmentCell extends React.Component<any> {

    static contextType = AppContext;
    context!: IReactContext<IAppState>;

    openAttachment = (attachmentUri: string) => async () => {
        window.open(attachmentUri, "_blank");
    }

    renderAttachmentButton = (attachmentUri: string) => {
        return (
            <button onClick={this.openAttachment(attachmentUri)} className="btn fa fa-paperclip"></button>
         )
    }

    render() {
        const { attachmentName, attachmentUri } = this.props.dataItem;

        return (
            <td>
                <Wrapper>
                    { (attachmentName !== "" && attachmentName !== null) 
                        && this.renderAttachmentButton(attachmentUri) }
                </Wrapper>
            </td>
        );
    }
}