/// <reference path="../../../../common-types.d.ts" />
/// <reference path="../../../../interfaces.d.ts" />
import * as React from 'react';
import { AppContext } from '../../../AppStateProvider';
import { Checkbox } from '@progress/kendo-react-inputs';
import { TrackerRole } from '../../../../common/enum/TrackerRole';
import { TrackerLedgerStatus } from '../../../../common/enum/TrackerLedgerStatus';
import { Wrapper } from '../../../shared-components/wrapper/Wrapper';

interface ILedgerCellProcessCheckboxProps {
    handleChange: (checked: boolean, ledgerIds: string) => void;
    ledgerIds: string;
    ledgerStatusId: number;
    checked: boolean;
}

export class LedgerCellProcessCheckbox extends React.Component<ILedgerCellProcessCheckboxProps> {

    static contextType = AppContext;
    context!: IReactContext<IAppState>;

    state = {
        checked: this.props.checked
    }

    handleChange = (event: any) => {
        const { checked } = event.target;

        this.props.handleChange(checked, this.props.ledgerIds);
    }

    render() {
        const { permissionToProcessTrackers } = this.context.state.user;

        return (
            <Wrapper>
                <td>
                    { permissionToProcessTrackers
                        && (this.props.ledgerStatusId === TrackerLedgerStatus.ReadyForPayroll) &&
                    <input type="checkbox" style={{marginLeft: "10px"}}
                                    checked={this.state.checked}
                                    onChange={this.handleChange}
                                />
                    }
                </td>
            </Wrapper>
        );
    }
}