/// <reference path="../../../../common-types.d.ts" />
/// <reference path="../../../../interfaces.d.ts" />
/// <reference path="../../../../types.d.ts" />
/// <reference path="../../../../data/data-models.d.ts" />
import * as React from 'react';
import { AppContext } from '../../../AppStateProvider';
import { Wrapper } from '../../../shared-components/wrapper/Wrapper';
import { updateLedgerStatus } from '../../../../service/TrackersService';
import { getAttachment } from '../../../../data/TrackersRepository';
import { TrackerLedgerStatus } from '../../../../common/enum/TrackerLedgerStatus';
import { BillingCodeClassification } from '../../../../common/enum/BillingCodeClassification';
import { TrackerRole } from '../../../../common/enum/TrackerRole';
import { loadTrackersGrid } from '../helpers';
import { StatusCommentsModal } from '../../../shared-components/status-comments/StatusCommentsModal';
import { ConfirmModal } from '../../../shared-components/confirm/ConfirmModal';

interface ILedgersGridRowDetailActionCellProps{
    dataItem: any;
    userId: number;
    openEditCloseDateModal: (ledgerId: string, closeDate: string) => void;
    setError: (error: boolean, errorMsg: string) => void;
    viewAllTrackers: boolean;
}

export class LedgersGridRowDetailActionCell extends React.Component<ILedgersGridRowDetailActionCellProps> {

    static contextType = AppContext;
    context!: IReactContext<IAppState>;

    handleUpdateLedgerStatus = async (ledgerStatusId: number, comments?: string) => {
        let id = this.context.state.statusCommentsModal.associatedLedgerIds != "" ? parseInt(this.context.state.statusCommentsModal.associatedLedgerIds) : this.props.dataItem.id;
        this.context.set(
            {
                confirmModal: {open: false, comments: ''},
                statusCommentsModal: {
                    open: false,
                    associatedLedgerIds: "",
                    comments: ""
                },
                trackers: { loadingLedgers: true }
            },
            );
        
        const success = await updateLedgerStatus(id, ledgerStatusId, comments);
        if (success.data.error == true) {
            this.props.setError(success.data.error, success.data.errorMsg);

            return;
        }

        var response = await loadTrackersGrid(this.context, this.props.viewAllTrackers);

        if(response.error == true){
            this.props.setError(response.error, response.errorMsg);

            return;
        }
    }


    confirmReject = async (ledgerStatusId: number, comments: string) =>{
        this.context.set({
            statusCommentsModal: {
                open: false,
                associatedLedgerIds: this.context.state.statusCommentsModal.associatedLedgerIds,
                comments: comments
            },
            confirmModal: {
                open: true,
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
                title: 'Reject Tracker',
                message: 'Are you sure you want to reject the tracker?',
                ledgerStatusId: ledgerStatusId,
                comments: comments,
                associatedLedgerIds: this.context.state.statusCommentsModal.associatedLedgerIds,
                groupStatusChange: false
            }
        })
    }

    confirmCancel = async (ledgerStatusId: number, comments: string) =>{
        this.context.set({
            statusCommentsModal: {
                open: false,
                associatedLedgerIds: this.context.state.statusCommentsModal.associatedLedgerIds,
                comments: comments
            },
            confirmModal: {
                open: true,
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
                title: 'Cancel Tracker',
                message: 'Are you sure you want to cancel the tracker?',
                ledgerStatusId: ledgerStatusId,
                comments: comments,
                associatedLedgerIds: this.context.state.statusCommentsModal.associatedLedgerIds,
                groupStatusChange: false
           }
        })
    }

    confirmModal = async (ledgerStatusId: number, comments: string) => {
        await this.handleUpdateLedgerStatus(ledgerStatusId, comments);
    }

    closeConfirmModal = () => {
        this.context.set({
            statusCommentsModal: { 
                open: true, 
                associatedLedgerIds: this.context.state.statusCommentsModal.associatedLedgerIds,
                comments: this.context.state.statusCommentsModal.comments 
            },
            confirmModal: {open: false, comments: ''}
        });
    }

    submitStatusComments = async (comments: string) => {

        if(comments == null || comments.length < 1){
            this.context.set({statusCommentsModal: {open: true, comments: comments, hasError: true, commentsError: "Comments required."}});
        }
        else{
            let ledgerStatusId = this.context.state.statusCommentsModal.ledgerStatusId;

            if(ledgerStatusId == TrackerLedgerStatus.Rejected){
                await this.confirmReject(ledgerStatusId, comments);
            }
            else{
                await this.confirmCancel(ledgerStatusId, comments);
            }
        }
    }

    closeStatusModal = () => {
        this.context.set({statusCommentsModal: {open: false, associatedLedgerIds: "", comments: ""}});
    }

    handleReject = (ledgerId: string) => {
        
        this.context.set({
            statusCommentsModal: {
                open: true,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Submit',
                title: 'Reject Tracker',
                commentsTitle: "Rejection Comments",
                ledgerStatusId: TrackerLedgerStatus.Rejected,
                hasError: false,
                commentsError: '',
                associatedLedgerIds: ledgerId,
                groupStatusChange: false
            },
            confirmModal: {comments: ''}
        })
    }

    handleCancel = (ledgerId: string) => {
        this.context.set({
            statusCommentsModal: {
                open: true,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Submit',
                title: 'Cancel Tracker',
                commentsTitle: "Cancellation  Comments",
                ledgerStatusId: TrackerLedgerStatus.Cancelled,
                hasError: false,
                commentsError: '',
                associatedLedgerIds: ledgerId,
                groupStatusChange: false
            },
            confirmModal: {comments: ''}
        })
    }

    openEditModal = async () => {
        const dataItem: ITrackerLedgerItem = this.props.dataItem;
        const billCodeName = `${dataItem.billingCodeName}`

        const rows = this.context.state.trackers.grid.data;
        const parent = rows.find(p => p.ledgerIds.indexOf(dataItem.id.toString()) > -1)

        const item: IEditTrackerItem = {
            id: dataItem.id,
            description: dataItem.description,
            classification: { name: parent.billingCodeClassificationName, id: parent.billingCodeClassificationId },
            billingCode: { name: billCodeName, id: dataItem.billingCodeId, text: billCodeName },
            claim: { name: parent.claimNumber, id: parent.claimId, damageClaimNo: parent.claimNumber },
            totalAmount: dataItem.totalAmount,
            hasAttachment: false,
            expenseDate: new Date(dataItem.expenseDate),
            userId: this.props.userId
        }

        const response = await getAttachment(dataItem.id);

        if(response.data.error == true){
            this.props.setError(response.data.error, response.data.errorMsg);

            return;
        }

        const savedFileName = response.data.attachment != null ? response.data.attachment.name : undefined;
        if (savedFileName) {
            item.savedFileName = savedFileName + "";
            item.attachmentUri = response.data.attachment.blobUri + "";
            item.hasAttachment = true
        }
        
        this.context.set({ editModal: { open: true, item }})
    }

    openEditCloseDateModal = () => {
        const dataItem: ITrackerLedgerItem = this.props.dataItem;
        const ledgerId = `${dataItem.id}`;
        const closeDate = `${dataItem.closeDate}`;

        this.props.openEditCloseDateModal(ledgerId, closeDate);
    }

    renderApproveButton = () => {
        return (
            <button onClick={() => this.handleUpdateLedgerStatus(TrackerLedgerStatus.ReadyForPayroll)} className="btn btn-success btn-sm action-button btn-margin">Approve</button>
         )
    }

    renderProcessButton = () => {
        return (
            <button onClick={() => this.handleUpdateLedgerStatus(TrackerLedgerStatus.Processed)} className="btn btn-primary btn-sm action-button btn-margin">Process</button>
        )
    }

    renderRejectButton = (ledgerId: string) => {
        return (
            <button onClick={() => this.handleReject(ledgerId)} className="btn btn-danger btn-sm action-button btn-margin">Reject</button>
        )
    }

    renderCancelButton = (ledgerId: string) => {
        return (
            <button onClick={() => this.handleCancel(ledgerId)} className="btn btn-danger btn-sm action-button btn-margin">Cancel</button>
        )
    }

    renderEditButton = () => {
        return (
            <button onClick={this.openEditModal} className="btn btn-success btn-sm action-button btn-margin">Edit</button>
        )
    }

    renderEditCloseDateButton = () => {
        return(
            <button onClick={this.openEditCloseDateModal} className="btn btn-success btn-sm action-button btn-margin">Edit Close Date</button>
        )
    }

    render() {
        
        const { userId, ledgerStatusId, billingCodeClassificationId, hideFromCTS } = this.props.dataItem;

        let ledgerId = this.props.dataItem.id;

        const { id, trackerRole, permissionToProcessTrackers, isSuperAdmin } = this.context.state.user

        let ledgerIdText = ledgerId + '';

        return (
            <td>
                <Wrapper>
                    { (trackerRole === TrackerRole.Admin || (trackerRole === TrackerRole.Reviewer && userId !== id)) 
                        && ledgerStatusId === TrackerLedgerStatus.PendingReview 
                        && this.renderApproveButton() }

                    { permissionToProcessTrackers 
                        && ledgerStatusId === TrackerLedgerStatus.ReadyForPayroll 
                        && this.renderProcessButton() }

                    { (trackerRole === TrackerRole.Admin || (trackerRole === TrackerRole.Reviewer && userId !== id))
                        && (ledgerStatusId === TrackerLedgerStatus.PendingReview ||ledgerStatusId === TrackerLedgerStatus.ReadyForPayroll)
                        && this.renderRejectButton(ledgerIdText) }

                    { ((userId === id) || (isSuperAdmin))
                        && (ledgerStatusId === TrackerLedgerStatus.PendingReview ||ledgerStatusId === TrackerLedgerStatus.ReadyForPayroll)
                        && hideFromCTS
                        && this.renderEditButton() }
 
                    { (userId === id) 
                        && (ledgerStatusId === TrackerLedgerStatus.PendingReview ||ledgerStatusId === TrackerLedgerStatus.ReadyForPayroll)
                        && hideFromCTS
                        && this.renderCancelButton(ledgerIdText) }
                    {
                        permissionToProcessTrackers &&
                        this.renderEditCloseDateButton()
                    }
                </Wrapper>
                {
                    this.context.state.statusCommentsModal.open && 
                    <StatusCommentsModal comments={this.context.state.statusCommentsModal.comments} submitModal={this.submitStatusComments} closeModal={this.closeStatusModal} ledgerIds={ledgerIdText} groupStatusChange={false}/>
                }
                {
                    this.context.state.confirmModal.open && 
                    <ConfirmModal confirmModal={this.confirmModal} closeModal={this.closeConfirmModal} ledgerIds={ledgerIdText} groupStatusChange={false}/>
                }
            </td>
        );
    }
}