/// <reference path="../../../../common-types.d.ts" />
/// <reference path="../../../../interfaces.d.ts" />
import * as React from 'react';
import { AppContext } from '../../../AppStateProvider';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Wrapper } from '../../../shared-components/wrapper/Wrapper';
import { TrackerRole } from '../../../../common/enum/TrackerRole';

interface ILedgerHeaderProcessCheckboxProps {
    handleChange: (checked: boolean) => void;
}

export class LedgerHeaderProcessCheckbox extends React.Component<ILedgerHeaderProcessCheckboxProps> {

    static contextType = AppContext;
    context!: IReactContext<IAppState>;

    handleChange = (event: any) => {
        const { checked } = event.target
        this.props.handleChange(checked);
    }

    render() {
        const { permissionToProcessTrackers } = this.context.state.user;

        return (
            <Wrapper>
            <div className="k-filtercell">
                { permissionToProcessTrackers &&
                 <input type="checkbox" style={{marginLeft: "13px"}}
                                checked={this.context.state.trackers.grid.checkAllForProcessing}
                                onChange={this.handleChange}
                            />
                }
            </div>
            </Wrapper>
        );
    }
}