/// <reference path="../../../../common-types.d.ts" />
/// <reference path="../../../../interfaces.d.ts" />
/// <reference path="../../../../types.d.ts" />
/// <reference path="../../../../data/data-models.d.ts" />
import * as React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { putTrackerCloseDate } from "../../../../data/TrackersRepository";
import { debug } from "webpack";
declare interface IEditLedgerCloseDateModalProps {
  open: boolean;
  ledgerId: string;
  ledgerCloseDate: string;
  closeDateList: any[];
  closeModal: (saved: boolean) => void;
  setError: (error: boolean, errorMsg: string) => void;
}

export class EditLedgerCloseDateModal extends React.Component<IEditLedgerCloseDateModalProps> {
  state = {
    ledgerCloseDate: this.props.ledgerCloseDate,
  };

  closeModal = () => {
    this.setState({ ledgerCloseDate: undefined });
    this.props.closeModal(false);
  };

  handleSave = async () => {
    const response = await putTrackerCloseDate(parseInt(this.props.ledgerId), {
      ledgerCloseDate: new Date(this.state.ledgerCloseDate).toISOString(),
    });

    if (response.data.error == true) {
      this.props.setError(response.data.error, response.data.errorMsg);
      return;
    }

    this.setState({ ledgerCloseDate: undefined });
    this.props.closeModal(true);
  };

  handleChange = (e) => {
    this.setState({
      ledgerCloseDate: e.target.value,
    });
  };

  render() {
    return (
      <div>
        {this.props.open && (
          <Dialog title={"Edit Close Date"} onClose={this.closeModal}>
            <DropDownList
              data={this.props.closeDateList}
              value={this.state.ledgerCloseDate}
              defaultValue={this.props.ledgerCloseDate}
              onChange={this.handleChange}
              popupSettings={{ className: "cls-option" }}
            />

            <DialogActionsBar>
              <button className="k-button" onClick={this.closeModal}>
                Close
              </button>
              <button className="k-button" onClick={this.handleSave}>
                Save
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
      </div>
    );
  }
}
