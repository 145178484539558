import * as React from 'react'
import { Grid, GridColumn, GridDataStateChangeEvent, GridExpandChangeEvent, GridFilterChangeEvent, GridPageChangeEvent, GridSortChangeEvent, GridEvent } from '@progress/kendo-react-grid';
import { AppContext } from '../../../AppStateProvider';
import { Wrapper } from '../../../shared-components/wrapper/Wrapper';
import { Row, Col } from 'react-bootstrap';
import { LedgersGridActionCell } from './LedgersGridActionCell';
import { LedgersGridRowDetail } from './LedgersGridRowDetail';
import { LedgersGridClaimCell } from './LedgersGridClaimCell';
import { LedgerHeaderProcessCheckbox } from './LedgerHeaderProcessCheckbox';
import { LedgerCellProcessCheckbox } from './LedgerCellProcessCheckbox';
import { LedgersGridBulkProcessButton } from './LedgersGridBulkProcessButton';
import { LedgersGridClassificationCell } from './LedgersGridClassificationCell'
import { GridDropdownFilterCell } from '../../../shared-components/grid-dropdown-filter-cell/GridDropdownFilterCell';
import { ClaimFilterCell } from '../../../shared-components/autocomplete/ClaimFilterCell';
import { getTrackerUsers, getAllClassifications, getBillingCodes } from '../../../../data/TrackersRepository';
import { getAllCloseDates } from '../../../../service/TrackersService';
import { getAllTrackersByStatusIds } from '../../../../service/ExpenseGroupService';
import * as moment from 'moment';
import '../../../../../node_modules/@progress/kendo-theme-default/dist/all.scss';
import './LedgersGrid.scss';
import { loadTrackersGrid } from '../helpers';
import { getTrackers } from '../../../../service/TrackersService';
import { getTrackerHistories } from '../../../../service/TrackersService';
import { batchUpdateLedgerStatus } from '../../../../service/TrackersService';
import { TrackerLedgerStatus } from '../../../../common/enum/TrackerLedgerStatus';

const defaultItem = (name) => ({ id: 999, name })

interface ILedgersGridProps{
    openEditCloseDateModal: (ledgerId: string, closeDate: string) => void;
    viewAllTrackers: boolean;
    setError: (error: boolean, errorMsg: string) => void
}

export class LedgersGrid extends React.Component<ILedgersGridProps> {

    static contextType = AppContext;
    context!: IReactContext<IAppState>;

    state = {
        checkAllForProcessing: false
    }

    async componentDidMount() {
        const filterState = this.context.state.trackers.grid.filterState;
        
        this.context.set({
            trackers: {
                loadingLedgers: true,
                totalAmountTotal: 0,
                amountPaidTotal: 0,
                amountDueTotal: 0,
            }
        });

        var viewAllTrackerUsers = this.props.viewAllTrackers == true;

        let serverError = false;
        let serverErrorMsg = "";

        //Initialize default sort
        this.context.state.trackers.grid.filterState.sort = [ 
            {field: "ledgerStatusName", dir: "asc"},
            {field: "userFullName", dir: "desc"}
        ];

        const getTrackerUsersPromise = getTrackerUsers(viewAllTrackerUsers);
        const getAllCloseDatesPromise = getAllCloseDates();
        const getAllClassificationsPromise = getAllClassifications();
        const getBillingCodesPromise = getBillingCodes();
        const getAllTrackersByStatusIdsPromise = getAllTrackersByStatusIds(filterState, true, false, false, false, this.props.viewAllTrackers, null, null);
        const getTrackersGrid = loadTrackersGrid(this.context, this.props.viewAllTrackers, this.context.state.trackers.grid.filterState);

        const trackerUsersResponse = await getTrackerUsersPromise;
        const allCloseDatesRespose = await getAllCloseDatesPromise;
        const allClassificationsResponse = await getAllClassificationsPromise;
        const billingCodesResponse = await getBillingCodesPromise;
        let getAllTrackersByStatusIdsResponse = await getAllTrackersByStatusIdsPromise;
        let getTrackersGridResponse = await getTrackersGrid;
        
        if(trackerUsersResponse.data.error == true){
            serverError = trackerUsersResponse.data.error;
            serverErrorMsg = trackerUsersResponse.data.errorMsg;
        }
        else if(allCloseDatesRespose.data.error == true){
            serverError = allCloseDatesRespose.data.error;
            serverErrorMsg = allCloseDatesRespose.data.errorMsg;
        }
        else if(allClassificationsResponse.data.error == true){
            serverError = allClassificationsResponse.data.error;
            serverErrorMsg = allClassificationsResponse.data.errorMsg;
        }
        else if(billingCodesResponse.data.error == true){
            serverError = billingCodesResponse.data.error;
            serverErrorMsg = billingCodesResponse.data.errorMsg;
        }
        
        if(getAllTrackersByStatusIdsResponse.error == true){
            serverError = getAllTrackersByStatusIdsResponse.error;
            serverErrorMsg = getAllTrackersByStatusIdsResponse.errorMsg;
            this.props.setError(serverError, serverErrorMsg); 
            return;
        }

        if (getTrackersGridResponse.error == true) {
            serverError = getTrackersGridResponse.console.error;
            serverErrorMsg = getTrackersGridResponse.errorMsg;
            this.props.setError(serverError, serverErrorMsg);
            return;
        }

        if(serverError == true){
            this.props.setError(serverError, serverErrorMsg);
        }

        const { data } = getTrackersGridResponse;
        const { totals, count } = getAllTrackersByStatusIdsResponse;

        let ledgerIdText = this.GetUrlParamtersByName("ledgerId");

        let ledgerId = ledgerIdText != null ? parseInt(ledgerIdText) : 0;

        let openLedgerHistoryModal = ledgerId > 0;
        
        let claimNumber = "";
        let billingCodeClassificationName = "";
        let ledgerStatusName = "";
        let userFullName = "";
        let closeDate = "";

        let ledgerHistoryData = [];

        if(openLedgerHistoryModal){

            var getTrackersResponse = await getTrackers(ledgerIdText);

            serverError = getTrackersResponse.error;
            serverErrorMsg = getTrackersResponse.errorMsg;

            if(serverError == true){
                this.props.setError(serverError, serverErrorMsg);
                return;
            }

            let ledgers = getTrackersResponse.ledgers;

            if(ledgers.length > 0){
                let ledger = ledgers[0];

                claimNumber = ledger.claimNumber;
                billingCodeClassificationName = ledger.billingCodeClassificationName;
                ledgerStatusName = ledger.ledgerStatusName;
                userFullName = ledger.userFullName;
                closeDate = ledger.closeDate;

                var getTrackerHistoriesResponse = await getTrackerHistories(ledgerId);

                if(getTrackerHistoriesResponse.error == true){
                    this.props.setError(getTrackerHistoriesResponse.error, getTrackerHistoriesResponse.errorMsg);
                    return;
                }

                ledgerHistoryData = getTrackerHistoriesResponse.ledgerHistories
            }
            else{
                openLedgerHistoryModal = false;
            }
        }

        this.context.set({
            user: {
                allowedUsers: trackerUsersResponse.data.trackerUsers,
                allowedClassifications: allClassificationsResponse.data.classifications,
                allowedCloseDates: allCloseDatesRespose.data.closeDates,
                billingCodes: billingCodesResponse.data.billingCodes,
            },
            trackers: {
                grid: {
                    data,
                    total: count,
                    initialData: data
                },
                loadingLedgers: false,
                totalAmountTotal: totals.totalAmountTotal,
                amountPaidTotal: totals.amountPaidTotal,
                amountDueTotal: totals.amountDueTotal
            },
            ledgerHistoryModal: {
                open: openLedgerHistoryModal,
                ledgerId: ledgerId,
                claimNumber: claimNumber,
                billingCodeClassificationName: billingCodeClassificationName,
                ledgerStatusName: ledgerStatusName,
                userFullName: userFullName,
                closeDate: closeDate,
                grid:{
                    data: ledgerHistoryData
                }
            }
        });
    }

    GetUrlParamtersByName = (name: string) => {
        let url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    FooterSum = (props) => {
        let total = 0;
        
        let prefixText = "";

        if(props.field == "totalAmount"){
            total = this.context.state.trackers.totalAmountTotal;
            prefixText = "Total Submitted";
        }
        else if(props.field == "amountPaid"){
            total = this.context.state.trackers.amountPaidTotal;
            prefixText = "Total Paid Prior";
        }
        else if(props.field == "amountDue"){
            total = this.context.state.trackers.amountDueTotal;
            prefixText = "Total Adjusted";
        }

        
        if(total == null){
            total = 0
        }

        return (
            <td colSpan={props.colSpan} style={props.style}>
                {prefixText} <br/>  ${total.toLocaleString(navigator.language, {minimumFractionDigits: 2})}
            </td>
        );
    }

    FooterLabel = (props) => {
        return (
            <td colSpan={props.colSpan} style={{textAlign: "right"}}>
                Subtotals:
            </td>
        );
    }

    onDataStateChange = (e: GridDataStateChangeEvent) => {
        const filterState = { ...e.data } as any;
        
        this.context.set({
            trackers: {
                grid: {
                    filterState
                }
            }
        });
    }

    onRowExpanded = async (e: GridExpandChangeEvent) => {

        const expanded = e.value;

        const { ledgerIds } = e.dataItem
        e.dataItem.details = {
            expanded: expanded
        };

        if(e.dataItem.trackerLedgerItems == null || e.dataItem.trackerLedgerItems.length == 0){
            const data = await getTrackers(ledgerIds);

            if(data.error == true){
                this.props.setError(data.error, data.errorMsg);
                return;
            }

            e.dataItem.trackerLedgerItems = data.ledgers;
        }

        this.forceUpdate();
    }
	

    onFilterChange = async (e: GridFilterChangeEvent) => {
        const filter = e.filter as any;
        var response = await loadTrackersGrid(this.context, this.props.viewAllTrackers, { filter });

        if(response.error == true){
            this.props.setError(response.error, response.errorMsg);

            return;
        }
    }

    onSortChange = async (e:GridSortChangeEvent) => {
        const { sort } = e;
        var response = await loadTrackersGrid(this.context, this.props.viewAllTrackers, { sort });

        if(response.error == true){
            this.props.setError(response.error, response.errorMsg);

            return;
        }
    }

    onScroll = async (event: GridEvent) => {
        const { scrollTop, scrollHeight, clientHeight } = event.nativeEvent.target;
        if (scrollTop < scrollHeight - clientHeight) {
            return;
        }
        
        const { loadingLedgers, grid } = this.context.state.trackers;
        if (loadingLedgers || grid.data.length === grid.total) {
            return
        }
        
        const { filterState } = grid;
        filterState.skip += filterState.take;

        this.context.set({ 
            trackers: {
                grid: { filterState },
                loadingLedgers: true 
            }
        });

        const response = await getAllTrackersByStatusIds(filterState, grid.showPending, grid.showProcessed, grid.showCancelled, grid.showRejected, this.props.viewAllTrackers, grid.closeDateBegin, grid.closeDateEnd);
        
        if(response.error == true){
            this.props.setError(response.error, response.errorMsg);
            return;
        }

        if(this.state.checkAllForProcessing){

            response.data.forEach((element) => {
                element.checkedForBulkProcess = true;
            });

        }
        
        const data = grid.data.concat(response.data)

        this.context.set({
            trackers: {
                loadingLedgers: false,
                grid: {
                    loadedCount: data.length,
                    data,
                    total: response.count,
                }
            }
        });
    }

    getStatusOptions = () => {
        return [
            { id: 1, name: 'Pending Review' },
            { id: 2, name: 'Ready for Payroll' },
        ]
    }

    getCloseDateOptions = () => {
        return this.context.state.user.allowedCloseDates.map((w, id) => {
            const name = moment(w).format("MM/DD/YYYY")
            return { id, name }
        })
    }

    handleCheckAllForProcessingChange = (checked: boolean) => {
        let data = this.context.state.trackers.grid.data;

        data.forEach(element => {
            if(element.ledgerStatusId == TrackerLedgerStatus.ReadyForPayroll){
                element.checkedForBulkProcess = checked;
            }
        });

        this.setState({
            checkAllForProcessing: checked
        });

        this.context.set({
            trackers: {
                grid: {
                    data: data,
                    checkAllForProcessing: checked
                }
            }
        });
    }

    handleCheckForProcessingChange = (checked: boolean, ledgerIds: string) => {
        let data = this.context.state.trackers.grid.data;

        data.forEach(element => {
            if(element.ledgerIds == ledgerIds){
                element.checkedForBulkProcess = checked;
            }
        });

        this.context.set({
            trackers: {
                grid: {
                    data: data
                }
            }
        });
    }

    handleBulkProcessButtonClick = async () => {
        let data = this.context.state.trackers.grid.data;

        var ledgerIds = "";

        data.forEach(element => {
            if(element.checkedForBulkProcess){
                if(ledgerIds.length > 0){
                    ledgerIds += ",";
                }
                ledgerIds += element.ledgerIds;
            }
        });

        if(ledgerIds.length > 0){
            this.context.set(
                {
                    trackers: { 
                        loadingLedgers: true, 
                        grid: {
                            checkAllForProcessing: false
                        }
                    }
                },
                );
        
            const success = await batchUpdateLedgerStatus(ledgerIds, TrackerLedgerStatus.Processed, "");
            if (success.data.error == true) {
                this.props.setError(success.data.error, success.data.errorMsg);
    
                return;
            }

            var response = await loadTrackersGrid(this.context);

            if(response.error == true){
                this.props.setError(response.error, response.errorMsg);
    
                return;
            }
        }
    }

    setError = (error: boolean, errorMsg: string) => {
        this.props.setError(error, errorMsg);
    }

    render() {
        const { grid } = this.context.state.trackers;
        const { user } = this.context.state;
        const users = user.allowedUsers.map(w => ({ id: w.id, name: w.userFullName }))
        console.log("LedgersGrid render");
        return (<Wrapper>
            <Grid className="ledgers-grid" data={this.context.state.trackers.grid.data}
                reorderable={true}
                resizable={true}

                sortable={{mode: "multiple"}}
                sort={grid.filterState.sort}
                
                skip={grid.filterState.skip}
                take={grid.filterState.take}
                total={grid.total}
                
                filterable={true}
                filter={grid.filterState.filter}
                onFilterChange={e => this.onFilterChange(e)}
                onSortChange={e => this.onSortChange(e)}

                scrollable="scrollable"
                onScroll={e => this.onScroll(e)}
                
                detail={e => <LedgersGridRowDetail dataItem = { e.dataItem} openEditCloseDateModal={this.props.openEditCloseDateModal} viewAllTrackers={this.props.viewAllTrackers} setError={this.props.setError} />}
                expandField="details.expanded"
                onExpandChange={e => this.onRowExpanded(e)}

                onDataStateChange={e => this.onDataStateChange(e)}
            >
                <GridColumn width={50}
                    filterCell={(props) => <LedgerHeaderProcessCheckbox handleChange={this.handleCheckAllForProcessingChange}  />}
                    cell={(props) => <LedgerCellProcessCheckbox handleChange={this.handleCheckForProcessingChange} checked={props.dataItem.checkedForBulkProcess} ledgerIds={props.dataItem.ledgerIds} ledgerStatusId={props.dataItem.ledgerStatusId} />}
                />
                
                <GridColumn field="userFullName" title="Tracker Recipient"
                    filterCell={(props) => <GridDropdownFilterCell {...props} data={users} defaultItem={defaultItem("Select Tracker Recipient")}  />}/>
                
                <GridColumn field="claimNumber" title="Claim" 
                    filterCell={(props) => <ClaimFilterCell {...props} setError={this.props.setError} /> }
                    cell={(props) => <LedgersGridClaimCell {...props} />} />
                
                <GridColumn field="billingCodeClassificationName" title="Classification" width={200}
                    filterCell={(props) => <GridDropdownFilterCell {...props} data={user.allowedClassifications} defaultItem={defaultItem("Select Classification")} />} 
                    cell={(props) => <LedgersGridClassificationCell dataItem={props.dataItem} setError={this.setError} />}
                />

                <GridColumn field="ledgerStatusName" title="Status"
                    filterCell={(props) => <GridDropdownFilterCell {...props} data={this.getStatusOptions()} disabled={grid.showProcessed || grid.showCancelled || grid.showRejected}
                        defaultItem={defaultItem("Select Status")} />} />
                
                <GridColumn field="closeDate" title="Close Date"
                    filterCell={(props) => <GridDropdownFilterCell {...props} data={this.getCloseDateOptions()}
                        defaultItem={defaultItem("Select Date" )} />} footerCell={this.FooterLabel} />
                
                <GridColumn field="totalAmount"  title="$Submitted" format="{0:c}" filterable={false} width={100} footerCell={this.FooterSum} />
                <GridColumn field="amountPaid" title="$Paid Prior" format="{0:c}" filterable={false} width={100} footerCell={this.FooterSum} />
                <GridColumn field="amountDue" title="$Adjusted" format="{0:c}" filterable={false} width={100} footerCell={this.FooterSum}  />
                <GridColumn title="Actions" 
                    filterCell={(props) => <LedgersGridBulkProcessButton onClick={this.handleBulkProcessButtonClick}  />}
                    cell={(props) => <LedgersGridActionCell dataItem={props.dataItem} viewAllTrackers={this.props.viewAllTrackers} setError={this.setError}/>} groupable={false} sortable={false} width={164} />
            </Grid>
        </Wrapper>);
    }
}