import axios from 'axios';

export const RegisterUnauthorizedAction = () => {

    // intercept 401 responses and prompt the user to login
    axios.interceptors.response.use(response => {

        if (response.status === 401) {
            
            // redirect to cts dashboard screen
            window.location.href = appSettings.CtsUrl + '/TrackersRedirectPage.aspx';
        }

        return response;
    }, error => {

        if (error.response.status === 401) {
            
            // redirect to cts dashboard screen
            window.location.href = appSettings.CtsUrl + '/TrackersRedirectPage.aspx';
        }

        return Promise.reject(error);
    });
}
