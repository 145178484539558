/// <reference path="../common-types.d.ts" />
/// <reference path="./data-models.d.ts" />
import axios, { AxiosResponse } from 'axios'

export const getTrackers = async (ledgerIds: string): Promise<AxiosResponse<ITrackerLedgerItemResponse>> => {
    return await axios.get(`${appSettings.ApiUrl}/api/trackerledgers?ledgerIds=${ledgerIds}`, { 
        withCredentials: true,
    });
}

export const getTrackerUsers = async (viewAllTrackerUsers: boolean): Promise<AxiosResponse<ITrackerUserResponse>> => {
    return await axios(`${appSettings.ApiUrl}/api/trackerledgers/users?viewAllTrackerUsers=` + viewAllTrackerUsers, { withCredentials: true })
}

export const approveTrackers = async (ids: number[]): Promise<AxiosResponse<ITrackerUser[]>> => {
    return await axios.put(`${appSettings.ApiUrl}/api/trackerledgers/approve`, {
        TrackerIds: ids
    }, { withCredentials: true })
}

export const postTracker = async (payload): Promise<AxiosResponse> => {
    return await axios.post(`${appSettings.ApiUrl}/api/trackerledgers/add-ledger`, payload, { withCredentials: true })
}

export const putTracker = async (id: number, payload: any): Promise<AxiosResponse> => {
    return await axios.put(`${appSettings.ApiUrl}/api/trackerledgers/${id}`, payload, { withCredentials: true })
}

export const putTrackerCloseDate = async (id: number, payload: any): Promise<AxiosResponse> => {
    return await axios.put(`${appSettings.ApiUrl}/api/trackerledgers/${id}/closedate`, payload, { withCredentials: true })
}

export const putLedgerHistoryComment = async (payload: any): Promise<AxiosResponse> => {
    return await axios.put(`${appSettings.ApiUrl}/api/trackerledgers/putLedgerHistoryComment`, payload, { withCredentials: true })
}

export const putTrackers = async (ledgers: ITrackerLedgerItem[]): Promise<AxiosResponse> => {
    return await axios.put(`${appSettings.ApiUrl}/api/trackerledgers/update-ledgers`, ledgers, { withCredentials: true })
}

export const getAllClassifications = async (): Promise<AxiosResponse<IGetAllClassificationsResponse>> => {
    return await axios(`${appSettings.ApiUrl}/api/trackerledgers/classifications`, { withCredentials: true })
}

export const getAllCloseDates = async (): Promise<AxiosResponse<IGetAllCloseDatesResponse>> => {
    return await axios(`${appSettings.ApiUrl}/api/trackerledgers/close-dates`, { withCredentials: true })
}

export const getAllTrackerUsers = async (): Promise<AxiosResponse<ITrackerUserResponse>> => {
    return await axios(`${appSettings.ApiUrl}/api/trackerledgers/tracker-users-all`, { withCredentials: true })
}

export const getBillingCodes = async (): Promise<AxiosResponse> => {
    return await axios(`${appSettings.ApiUrl}/api/trackerledgers/billing-codes`, { withCredentials: true })
}

export const claimSearch = async (query): Promise<AxiosResponse> => {
    const response = await axios(`${appSettings.ApiUrl}/api/trackerledgers/claims?query=${query}`, { withCredentials: true })

    response.data.claims = response.data.claims.map(d => ({
        ...d,
        text: `${d.damageClaimNo} - ${d.damagerName || 'Unknown Damager'} : ${d.damageAddress1}`
    }));

    return response;
}

export const postAttachment = async (ledgerId, file): Promise<AxiosResponse> => {
    const formData = new FormData()
    formData.append('payload', file)

    return await axios.post(`${appSettings.ApiUrl}/api/trackerledgers/${ledgerId}/attachment`, formData, {
        withCredentials: true,
        headers: { 'Content-Type': 'multipart/form-data' }
    })
}

export const putAttachment = async (ledgerId, file): Promise<AxiosResponse> => {
    const formData = new FormData()
    formData.append('payload', file)

    return await axios.put(`${appSettings.ApiUrl}/api/trackerledgers/${ledgerId}/attachment`, formData, {
        withCredentials: true,
        headers: { 'Content-Type': 'multipart/form-data' }
    })
}

export const deleteAttachment = async (ledgerId): Promise<AxiosResponse> => {
    return await axios.delete(`${appSettings.ApiUrl}/api/trackerledgers/${ledgerId}/attachment`, { withCredentials: true })
}

export const getAttachment = async (ledgerId): Promise<AxiosResponse> => {
    return await axios.get(`${appSettings.ApiUrl}/api/trackerledgers/${ledgerId}/attachment`, { withCredentials: true })
}

export const getTrackerHistories = async (ledgerId: number): Promise<AxiosResponse<IGetTrackerHistoriesResponse>> => {
    return await axios.get(`${appSettings.ApiUrl}/api/trackerledgers/ledgerHistory?ledgerId=${ledgerId}`, { 
        withCredentials: true,
    });
}