import * as React from 'react';
import { AutoComplete } from '@progress/kendo-react-dropdowns';
import { claimSearch } from '../../../data/TrackersRepository';

interface IGridTypeAheadFilterCellProps {
    onChange: (payload: { value: INameIdPair, operator: string, syntheticEvent: any }) => void;
    value: INameIdPair;
    setError: (error: boolean, errorMsg: string) => void
}

export class ClaimFilterCell extends React.Component<IGridTypeAheadFilterCellProps> {
    
    state = { value: undefined, data: [], opened: false };

    getPayload = (value) => {
        return {
            value,
            operator: 'eq',
            syntheticEvent: "claim"
        }
    }

    handleChange = async (event) => {
        const { value } = event.target
        
        this.setState(state => ({ ...state, value }));

        if (!value) {
            this.props.onChange(this.getPayload(undefined));
            return;
        }

        if (value.length === 9) {
            const opt = this.state.data.find((d: any) => d.damageClaimNo === value);
            const payload = this.getPayload({ id: opt.id, name: value })
            this.props.onChange(payload);
            return;
        }

        if (value.length > 4) {
            const response = await claimSearch(value);

            if(response.data.error == true){
                this.props.setError(response.data.error, response.data.errorMsg);

                return;
            }

            this.setState(state => ({
                ...state,
                data: response.data.claims,
                opened: true
            }))
            return;
        }
        
        this.setState(state => ({ ...state, opened: false }))
    }

    componentDidMount = () => {
        if (this.props.value) {
            this.setState({
                ...this.state,
                value: this.props.value.name
            })
        }
    }

    render() {
        return (
            <div className="k-filtercell">
                <AutoComplete
                    opened={this.state.opened}
                    data={this.state.data}
                    textField="damageClaimNo"
                    value={this.state.value}
                    onChange={this.handleChange}
                />
            </div>
        );
    }
}

