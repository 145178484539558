import * as React from 'react';
import { AutoComplete } from '@progress/kendo-react-dropdowns';
import { claimSearch } from '../../../data/TrackersRepository';

interface IClaimAutoCompleteTextBoxProps {
    onChange: (payload: { value: INameIdPair, operator: string, syntheticEvent: any }) => void;
    value: INameIdPair;
    textField: string;
    label?: string;
    valid?: boolean;
    setError: (error: boolean, errorMsg: string) => void;
}

export class ClaimAutoCompleteTextBox extends React.Component<IClaimAutoCompleteTextBoxProps> {
    
    state = { value: undefined, data: [], opened: false };

    getPayload = (value) => {
        return {
            value,
            operator: 'eq',
            syntheticEvent: "claim"
        }
    }

    handleChange = async (event) => {
        const { value } = event.target
        
        if (!value) {
            this.props.onChange(this.getPayload(undefined));
            this.setState(state => ({ ...state, opened: false, data: [], value: undefined }))
        }
        else if (value.length >= 9) {
            const val = value.substring(0, 9)
            const opt = this.state.data.find((d: any) => d.damageClaimNo === val);
            const payload = this.getPayload({ id: opt.id, name: val })
            this.props.onChange(payload);
            this.setState(state => ({ ...state, opened: false, value: val }))
        }
        else if (value.length > 4) {
            const payload = this.getPayload({ id: value, name: value })
            this.props.onChange(payload);
            const response = await claimSearch(value);

            if(response.data.error == true){
                this.props.setError(response.data.error, response.data.errorMsg);
                return;
            }else{
                this.setState(state => ({
                    ...state,
                    value,
                    data: response.data.claims,
                    opened: true
                }))
            }
        }
        else {
            const payload = this.getPayload({ id: value, name: value })
            this.props.onChange(payload);
            this.setState(state => ({ ...state, opened: false, value }))
        }
    }

    componentDidMount = () => {
        if (this.props.value) {
            this.setState({
                ...this.state,
                value: this.props.value.name
            })
        }
    }

    render() {
        return (
            <div className="k-filtercell">
                <AutoComplete
                    label={this.props.label}
                    opened={this.state.opened}
                    data={this.state.data}
                    textField={this.props.textField}
                    value={this.state.value}
                    onChange={this.handleChange}
                    onBlur={() => this.setState({ ...this.state, opened: false })}
                    onFocus={() => this.setState({ ...this.state, opened: this.state.value && this.state.value.length > 4 })}
                    popupSettings={{ className: 'cls-option' }}
                    valid={this.props.valid}
                />
            </div>
        );
    }
}

