import * as React from 'react';
import { Spinner } from 'react-bootstrap';
import './OverlayLoader.scss';

export const OverlayLoader: React.FunctionComponent<any> = () => {
    return (
        <div className='overlay'>
            <Spinner animation='grow' variant='primary' />
        </div>
    )
} 