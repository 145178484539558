/// <reference path="../../../../common-types.d.ts" />
/// <reference path="../../../../interfaces.d.ts" />
/// <reference path="../../../../types.d.ts" />
/// <reference path="../../../../data/data-models.d.ts" />
import './AddTracker.scss'
import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'react-bootstrap';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { postTracker, postAttachment } from '../../../../data/TrackersRepository';
import { ClaimAutoCompleteTextBox } from '../../../shared-components/autocomplete/ClaimAutoCompleteTextBox';
import { AppContext } from '../../../AppStateProvider';
import { loadTrackersGrid } from '../helpers'
import { getAllTrackerUsers } from '../../../../service/TrackersService';
import { getAuthStatus } from '../../../../data/IdentityRepository';
import { getTrackerUsers } from '../../../../data/TrackersRepository';
var Enumerable = require('linq');

declare interface IAddTrackerModalProps {
    isOpen: boolean;
    closeModal: () => void;
    setError: (error: boolean, errorMsg: string) => void
    viewAllTrackers: boolean;
}

declare interface IAddTrackerModalState {
    description: string,
    classification?: INameIdPair,
    billingCode?: INameIdPair,
    claim?: string,
    claimId?: number,
    totalAmount?: number,
    file?: File,
    user: string,
    userId: number,
    descriptionError: boolean,
    classificationError: boolean,
    billingCodeError: boolean,
    claimError: boolean,
    amountError: boolean,
    fileError: boolean,
    totalAmountErrorText: string,
    expenseDate: Date,
    expenseDateError: boolean,
    trackerUsers: ITrackerUser[]
}

export class AddTrackerModal extends React.Component<IAddTrackerModalProps, IAddTrackerModalState> {

    private fileRef = React.createRef<HTMLInputElement>();
    static contextType = AppContext;
    context!: IReactContext<IAppState>;

    state = {
        description: '',
        classification: undefined,
        billingCode: undefined,
        claim: undefined,
        claimId: undefined,
        totalAmount: undefined,
        file: undefined,
        user: undefined,
        userId: undefined,
        descriptionError: false,
        classificationError: false,
        billingCodeError: false,
        claimError: false,
        amountError: false,
        fileError: false,
        totalAmountErrorText: '',
        expenseDate: undefined,
        expenseDateError: false,
        trackerUsers: []
    }

    async componentDidMount () {
        const response = await getAuthStatus();
        
        var getAllTrackerUsersResponse = await getAllTrackerUsers();

        if(response.data.error == true){
            this.props.setError(
                response.data.error,
                response.data.errorMsg
            );
        }
        else if(getAllTrackerUsersResponse.error == true){
            this.props.setError(
                getAllTrackerUsersResponse.error,
                getAllTrackerUsersResponse.errorMsg
            );
        }
        
        const userId = response.data.trackerUser.id;

        var trackerUsers = getAllTrackerUsersResponse.trackerUsers;

        var user = Enumerable.from(trackerUsers).where(i => i.id == userId).firstOrDefault();
        
        this.setState({
            userId: userId,
            user: user,
            trackerUsers: trackerUsers
        });
    }

    hasError = (state) => {
        const { descriptionError, classificationError, billingCodeError, claimError, amountError, fileError, expenseDateError} = state
        return (descriptionError || classificationError || billingCodeError || claimError || amountError || fileError || expenseDateError)
    }

    handleSave = async () => {
        const newState = {
            ...this.state
        };

        this.validateFormValue(newState, 'description');
        this.validateFormValue(newState, 'classification');
        this.validateFormValue(newState, 'billingCode');
        this.validateFormValue(newState, 'totalAmount');    
        this.validateFormValue(newState, 'claim');
        this.validateFormValue(newState, 'expenseDate');

        // do not require file if billing code selected is phone/internet or tolls
        if(newState.billingCode == null || (newState.billingCode.id != 6215 && newState.billingCode.id != 6230 && 
            newState.billingCode.id != 6200 && newState.billingCode.id != 6235 && newState.billingCode.id != 6255
            ))
        {
            this.validateFormValue(newState, 'file');
        }
        else{
            newState.fileError = false;
        }

        if (this.hasError(newState)) {
            this.setState(newState)
            return;
        }

        this.context.set({ trackers: { loadingLedgers: true } });

        const response = await postTracker(this.getPayload())
        
        if(response.data.error == true){
            this.props.setError(
                response.data.error,
                response.data.errorMsg
            );

            return;
        }

        if (this.state.file && response.data.error == false) {
            const ledgerId = response.data.id;

            var postAttachmentResponse = await postAttachment(ledgerId, this.state.file);

            if(postAttachmentResponse.data.error == true){
                this.props.setError(
                    postAttachmentResponse.data.error,
                    postAttachmentResponse.data.errorMsg
                );

                return;
            }
        }

        this.reset();
        var viewAllTrackers = this.props.viewAllTrackers == true;
        
        const usersResponse = await getTrackerUsers(viewAllTrackers);

        if(usersResponse.data.error == true){
            this.props.setError(
                usersResponse.data.error,
                usersResponse.data.errorMsg
            );

            return;
        }

        this.context.set({ user:{
            allowedUsers: usersResponse.data.trackerUsers
        } 
        });
        
        this.props.closeModal();
        var loadTrackersGridResponse = await loadTrackersGrid(this.context, this.props.viewAllTrackers);

        if(loadTrackersGridResponse.error == true){
            this.props.setError( loadTrackersGridResponse.error, loadTrackersGridResponse.errorMsg);

            return;
        }
    }

    getPayload = () => {
        const { description, classification, billingCode, claimId, totalAmount, expenseDate, userId } = this.state;
        return {
            description,
            billingCodeClassificationId: typeof classification !== "undefined" ? classification.id : undefined,
            billingCodeId: typeof billingCode !== "undefined" ? billingCode.id : undefined,
            claimId: typeof claimId !== "undefined" ? claimId : undefined,
            totalAmount,
            expenseDate,
            userId,
            hideFromCTS: true,
        }
    }

    handleCancel = () => {
        this.props.closeModal();
        this.reset();
    }

    handleChange = (name) => (e) => {
        const newState = {
            ...this.state,
            [name]: e.target.value
        }
        
        this.validateFormValue(newState, name);

        if(name == "user"){
            newState.userId = e.target.value.id;
        }

        if(name == "billingCode"){
            
            if(newState.billingCode != null && (newState.billingCode.id == 6215 || newState.billingCode.id == 6230 
                || newState.billingCode.id == 6200 || newState.billingCode.id == 6235 || newState.billingCode.id == 6255))
            {
                newState.fileError = false;
            }
        }
        
        this.setState(newState);
    }

    handleClaimChange = (e) => {
        if (e.value === undefined) {
            this.setState({
                ...this.state,
                claim: undefined,
                claimId: undefined,
                claimError: false,
            })
            return
        }
        
        const newState = {
            ...this.state,
            claim: e.value.name,
            claimId: e.value.id,
        }
        
        this.validateFormValue(newState, 'claim');

        this.setState(newState);
    }

    handleFileChange = (e) => {
        const file = e.target.files[0]
        this.state.fileError = false
        this.setState({
            ...this.state,
            file
        })
    }

    clearFile = (e) => {
        e.preventDefault();
        this.setState({
            ...this.state,
            file: undefined
        })
    }

    validateFormValue = (state, prop) => {
        if (prop === 'claim') {
            state.claimError = (state.claim && state.claim.length > 0 && state.claim.length !== 9)
            return
        }

        if(prop === 'totalAmount'){
            if(state.totalAmount == null || state.totalAmount == '' || state.totalAmount <= 0){
                state['amountError'] = true;
                state['totalAmountErrorText'] = 'Total Amount must be value greater than $0.00.'
            }
            else{
                state['amountError'] = false;
                state.totalAmount = parseFloat(state.totalAmount.toFixed(2));
            }
        }

        if (!state[prop]) {
            state[`${prop}Error`] = true;
        } else {
            state[`${prop}Error`] = false;
        }
    }

    getClassificationOptions = () => {
        return this.context.state.user.allowedClassifications;
    }

    getBillingCodeOptions = () => {
        return this.context.state.user.billingCodes.map(c => ({
            ...c,
            text: `${c.id} - ${c.name}`
        }));
    }

    reset = async () => {
        const response = await getAuthStatus();

        if(response.data.error == true){
            this.props.setError(
                response.data.error,
                response.data.errorMsg
            );

            return;
        }
        
        const userId = response.data.trackerUser.id;

        var user = Enumerable.from(this.state.trackerUsers).where(i => i.id == userId).firstOrDefault();

        this.setState({
            description: '',
            classification: undefined,
            billingCode: undefined,
            claim: undefined,
            totalAmount: undefined,
            file: undefined,
            userId: userId,
            user: user,
            descriptionError: false,
            classificationError: false,
            billingCodeError: false,
            claimError: false,
            amountError: false,
            fileError: false,
            totalAmountErrorText: '',
            expenseDate: undefined,
            expenseDateError: false
        });
    }

    handleClose = () => {
        this.reset();
        this.props.closeModal();
    }

    render() {

        const { isSuperAdmin } = this.context.state.user;
    
        return (
            <div >
                { this.props.isOpen &&
                <Dialog title={"Add New Tracker"} onClose={this.handleClose}>
                    <div className="add-tracker-component">
                        <Row className="tracker-form-row">
                            <Col sm={6}>
                                    Assigned User:
                                    <br />
                                    <DropDownList
                                        value={this.state.user}
                                        onChange={this.handleChange('user')}
                                        data={this.state.trackerUsers}
                                        dataItemKey="id"
                                        textField="userFullName"
                                        disabled={!isSuperAdmin}
                                        popupSettings={{ className: 'cls-option' }}
                                    />
                            </Col>
                            <Col sm={6} className="tracker-form-col">
                                <ClaimAutoCompleteTextBox
                                    onChange={this.handleClaimChange}
                                    value={this.state.claim}
                                    label="Claim"
                                    textField="text"
                                    valid={!this.state.claimError}
                                    setError={this.props.setError}
                                />
                            </Col>
                        </Row>
                        <Row className="tracker-form-row">
                            <Col sm={6}>
                                <Input
                                    value={this.state.description}
                                    onChange={this.handleChange('description')}
                                    label="Description"
                                    valid={!this.state.descriptionError}
                                />
                            </Col>
                            <Col sm={6} className="tracker-form-col">
                                <DropDownList
                                    value={this.state.billingCode}
                                    onChange={this.handleChange('billingCode')}
                                    label={'Billing Code'}
                                    data={this.getBillingCodeOptions()}
                                    dataItemKey="id"
                                    textField="text"
                                    popupSettings={{ className: 'cls-option' }}
                                    valid={!this.state.billingCodeError}
                                />
                            </Col>
                        </Row>
                        <Row className="tracker-form-row">
                            <Col sm={6}>
                                <DropDownList
                                    value={this.state.classification}
                                    onChange={this.handleChange('classification')}
                                    label={'Classification'}
                                    data={this.getClassificationOptions()}
                                    dataItemKey="id"
                                    textField="name"
                                    popupSettings={{ className: 'cls-option' }}
                                    valid={!this.state.classificationError}
                                />
                            </Col>
                        </Row>
                        <Row className="tracker-form-row">
                            <Col sm={3}>
                                <NumericTextBox
                                    value={this.state.totalAmount}
                                    onChange={this.handleChange('totalAmount')}
                                    label="Total Amount"
                                    format="c2"
                                    min={0}
                                    
                                    className="full-width"
                                    valid={!this.state.amountError}
                                />
                                <span style={{ visibility: this.state.amountError == true ? 'visible' : 'hidden', color: "red"}} >{this.state.totalAmountErrorText}</span>
                            </Col>
                            <Col sm={5} >
                                <div style={{marginTop: "-4px"}}>
                                    Expense Date: <br />
                                    <DatePicker value={this.state.expenseDate} className="expense-datepicker" title="Expense Date"  onChange={this.handleChange('expenseDate')} valid={!this.state.expenseDateError}/>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <input
                                    type="file"
                                    id="contained-button-file"
                                    style={{ display: 'none' }}
                                    onChange={this.handleFileChange}
                                    ref={this.fileRef}
                                    required
                                    // accept=".json"
                                />
                                <label htmlFor="contained-button-file" style={{paddingTop:'1rem'}}>
                                    <button className="btn btn-secondary pull-right" id="file-button" style={{ borderColor: this.state.fileError == true ? 'red' : ''}} onClick={() => this.fileRef.current.click()}>Choose File</button>
                                </label>
                                <div>
                                    { this.state.file &&
                                        <div>
                                            <div>{`${this.state.file.name}`}</div>
                                            <a href="" onClick={this.clearFile}>{'Clear'}</a>
                                        </div>
                                    }
                                    <span style={{ visibility: this.state.fileError == true ? 'visible' : 'hidden', color: "red"}} >File attachment is required.</span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <DialogActionsBar>
                        <button className="k-button" onClick={this.handleCancel}>Cancel</button>
                        <button className="k-button" onClick={this.handleSave}>Save</button>
                    </DialogActionsBar>
                </Dialog> }     
            </div>
        )
    }
}