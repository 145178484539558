import * as React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Home } from './pages/home/Home';
import { AppStateProvider } from './AppStateProvider';
import { Header } from './shared-components/header/Header';
import { Trackers } from './pages/trackers/Trackers';
import { OnAppStart } from './shared-components/app-start/OnAppStart';

export class App extends React.Component {
  render(): React.ReactNode {

    return (
      <AppStateProvider>
        <Router>
          <OnAppStart />
          <Header />
          <Route path="/" exact component={Home} />
          <Route path="/trackers/" exact component={Trackers} />
        </Router>
      </AppStateProvider >
    );
  }
}