/// <reference path="../../../../common-types.d.ts" />
/// <reference path="../../../../interfaces.d.ts" />
/// <reference path="../../../../types.d.ts" />
/// <reference path="../../../../data/data-models.d.ts" />
import * as React from 'react';
import { AppContext } from '../../../AppStateProvider';
import { getClaimDetails } from '../../../../data/ClaimRepository';
import { getTrackerHistories } from '../../../../service/TrackersService';
import { debug } from 'webpack';

declare interface ILedgerHistoryCommentCellProps {
    handleClick: (ledgerStatusId: number, comments: string) => void;
    ledgerHistoryId: number;
    statusComment: string;
    clicked: boolean;
}

export class LedgerHistoryCommentCell extends React.Component<ILedgerHistoryCommentCellProps> {

    static contextType = AppContext;
    context!: IReactContext<IAppState>;

    state = {
        ledgerHistoryId: this.props.ledgerHistoryId,
        comments: this.props.statusComment,
        clicked: this.props.clicked
    }

    handleClick = async (e)  => {
        e.preventDefault();

        this.props.handleClick(this.state.ledgerHistoryId, this.state.comments);
    }

    render() {

        const { ledgerHistoryModal } = this.context.state;

        return (
            <td>
                <input type="Text" className="grid-link" value={this.state.comments} onClick={this.handleClick} style={{ width: "100%",borderColor: ledgerHistoryModal.editLedgerHistoryId == this.state.ledgerHistoryId && this.state.clicked ? "red" : "", borderWidth: "medium"}} readOnly/>
            </td>
        );
    }
}