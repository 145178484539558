import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const bodyText = 'Initially this application includes modules for trackers, submitting timecards and payment requests, but over time new modules will be added and eventually replace CTS.'

export const Home: React.FunctionComponent = () => {
    return (
        <Container fluid id='homePage'>
            <Row>
                <Col sm={12}>
                    <label style={{ fontSize: '32px'}}>
                        {'Welcome to the New Phoenix Loss Control Portal'}
                    </label>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <p>{bodyText}</p>
                </Col>
            </Row>
        </Container>
    );
}
