import { Grid, GridColumn, GridDetailRow } from '@progress/kendo-react-grid';
import * as React from 'react';
import { LedgersGridRowDetailActionCell } from './LedgersGridRowDetailActionCell';
import { LedgersGridRowDetailAttachmentCell } from './LedgersGridRowDetailAttachmentCell';
import { getTrackers } from '../../../../service/TrackersService';
import { LedgersGridRowDetailStatusCell } from './LedgersGridRowDetailStatusCell';


interface ILedgersGridRowDetailProps {
    dataItem: { 
        ledgerIds: string, 
        userId: number,
        details: {
            expanded: boolean
        },
        value: any,
        trackerLedgerItems: ITrackerLedgerItem[]
    },
    openEditCloseDateModal: (ledgerId: string, closeDate: string) => void;
    viewAllTrackers: boolean,
    setError: (error: boolean, errorMsg: string) => void
}


export class LedgersGridRowDetail extends React.PureComponent<ILedgersGridRowDetailProps> {

    renderActionCell(dataItem: GridRowProps<any>) {
        const props = { ...dataItem }
        return (
            <LedgersGridRowDetailActionCell userId={this.props.dataItem.userId} dataItem = { props.dataItem } viewAllTrackers={this.props.viewAllTrackers} openEditCloseDateModal={this.props.openEditCloseDateModal} setError={this.props.setError}/>
        )
    }

    renderAttachmentCell(dataItem: GridRowProps<any>) {
        const props = { ...dataItem }
        return (
            <LedgersGridRowDetailAttachmentCell { ...props } />
        )
    }

    render() {
        const { trackerLedgerItems } = this.props.dataItem;

        return (
        <Grid className="detail-grid" data={ trackerLedgerItems }>
            <GridColumn title=" "
                cell={(props) => this.renderAttachmentCell(props)} 
                groupable={false} sortable={false} filterable={false} width={64}
            />
            <GridColumn field="billingCodeName" title="Billing Code" groupable={false} sortable={false} filterable={false} width={200}  />
            <GridColumn field="description" title="Description" groupable={false} sortable={false} filterable={false} />
            <GridColumn field="ledgerStatusName" title="Status/Change Log" width={150}
                    cell={(props) => <LedgersGridRowDetailStatusCell dataItem={props.dataItem} setError={this.props.setError} />}
                />
            <GridColumn field="createDate" title="Created Date" groupable={false} sortable={false} filterable={false} width={110} />
            <GridColumn field="expenseDate" title="Expense Date" groupable={false} sortable={false} filterable={false} width={110} />
            <GridColumn field="totalAmount" format="{0:c}" title="$Submitted" groupable={false} sortable={false} filterable={false} width={118} />
            <GridColumn field="amountPaid" format="{0:c}" title="$Paid Prior" groupable={false} sortable={false} filterable={false} width={118} />
            <GridColumn field="amountDue" format="{0:c}" title="$Adjusted" groupable={false} sortable={false} filterable={false} width={118} />
            <GridColumn title="Actions"
                cell={(props) => this.renderActionCell(props)} 
                groupable={false} sortable={false} filterable={false} width={136}
            />
        </Grid>);
    }
}