/// <reference path="../../../interfaces.d.ts"/>
/// <reference path="../../../common-types.d.ts"/>
import { getAllTrackersByStatusIds } from '../../../service/ExpenseGroupService';
import { TrackerLedgerStatus } from '../../../Common/enum/TrackerLedgerStatus';
import axios, { AxiosResponse } from 'axios'

export const loadTrackersGrid = async (context: IReactContext<IAppState>,
    viewAllTrackers: boolean = undefined, newFilterState: IFilterState = {}) : Promise<any> => {

    context.set({ trackers: { loadingLedgers: true } });
    const { grid } = context.state.trackers;
    const { filterState, showPending, showCancelled, showProcessed, showRejected, closeDateBegin, closeDateEnd } = grid;
    
    const page = {
        take: grid.loadedCount < filterState.take ? filterState.take : grid.loadedCount, 
        skip: 0 
    };

    const filterDescriptor = {
        ...filterState,
        ...newFilterState,
        ...page,
    };

    const { data, totals, count, error, errorMsg } = await getAllTrackersByStatusIds(filterDescriptor, showPending, showProcessed, showCancelled, showRejected, viewAllTrackers, closeDateBegin, closeDateEnd);

    if(error == false){
        context.set({
            trackers: {
                grid: {
                    data,
                    total: count,
                    filterState: filterDescriptor
                },
                loadingLedgers: false,
                totalAmountTotal: totals.totalAmountTotal,
                amountPaidTotal: totals.amountPaidTotal,
                amountDueTotal: totals.amountDueTotal
            },
        });
    }

    return {
        error,
        errorMsg
    };
}