import * as React from 'react';
import { update, reset, request, StateProvider } from '../common/context/StateProvider';

const defaultContext: IReactContext<IAppState> = {
    state: {
        trackers: {
            loadingLedgers: false,
            amountDueTotal: 0,
            amountPaidTotal: 0,
            totalAmountTotal: 0,
            grid: {
                data: [],
                total: 0,
                loadedCount: 0,
                initialData: [],
                filterState: {
                    sort: [ 
                        {field: "ledgerStatusName", dir: "asc"},
                        {field: "userFullName", dir: "desc"}
                    ],
                  take: 20,
                  skip: 0
                },
                showPending: true,
                showProcessed: false,
                showCancelled: false,
                showRejected: false,
                checkAllForProcessing: false,
                claimDetailDisplayable: false,
                closeDateBegin: null,
                closeDateEnd: null
            }
        },
        editModal: {
            open: false,
            item: undefined,
        },
        confirmModal: {
            open: false,
            title: '',
            message: '',
            comments: '',
            ledgerStatusId: 0,
            associatedLedgerIds: '',
            groupStatusChange: false,
        },
        statusCommentsModal: {
            open: false,
            title: '',
            comments: '',
            commentsTitle: '',
            ledgerStatusId: 0,
            hasError: false,
            commentsError: '',
            associatedLedgerIds: '',
            groupStatusChange: false,
        },
        claimDetailModal: {
            open: false,
            item: undefined,
        },
        user: {
            id: undefined,
            userFullName: undefined,
            authenticated: false,
            trackerRole: '',
            allowedUsers: [],
            allowedClassifications: [],
            allowedCloseDates: [],
            billingCodes: [],
            permissionToProcessTrackers: false,
            isSuperAdmin: false,
            isLead: false,
        },
        ledgerHistoryModal: {
            open: false,
            userFullName: '',
            ledgerId: 0,
            claimNumber: '',
            billingCodeClassificationName: '',
            ledgerStatusName: '',
            closeDate: '',
            editStatusComment: '',
            editLedgerHistoryId: 0,
            hasError: false,
            statusMessage: '',
            commentCellClicked: false,
            grid:{
                data: []
            }
        }
    },
    set: update,
    reset: reset,
    fetch: request
};

export const AppContext: React.Context<IReactContext<IAppState>> = React.createContext(defaultContext);

export class AppStateProvider extends StateProvider<IAppState> {

    constructor(props: any) {
        super(props, AppContext, defaultContext.state, appSettings.Environment === 'development')
    }
}