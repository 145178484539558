/// <reference path="../../../../common-types.d.ts" />
/// <reference path="../../../../interfaces.d.ts" />
/// <reference path="../../../../types.d.ts" />
/// <reference path="../../../../data/data-models.d.ts" />
import * as React from 'react';
import { AppContext } from '../../../AppStateProvider';

export class LedgersGridClaimCell extends React.Component<GridRowProps<ITracker>> {

    static contextType = AppContext;
    context!: IReactContext<IAppState>;

    render() {
        const { claimId, claimNumber } = this.props.dataItem;
        return (
            <td>
                <a className="grid-link" href={`${appSettings.CtsUrl}/ClaimManager.aspx?claimid=${claimId}`} target="_blank">{claimNumber}</a>
            </td>
        );
    }
}