/// <reference path="../../../common-types.d.ts" />
/// <reference path="../../../interfaces.d.ts" />
import * as React from 'react';
import { AppContext } from '../../AppStateProvider';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { debug } from 'webpack';
import ReactDOM = require('react-dom');

declare interface IStatusCommentsModalProps {
    submitModal: (comments: string) => void;
    closeModal: () => void;
    comments: string;
    ledgerIds: string;
    groupStatusChange: boolean;
}

declare interface IStatusCommentsState {
    comments: string,
    ledgerIds: string,
    groupStatusChange: boolean
}

export class StatusCommentsModal extends React.Component<IStatusCommentsModalProps, IStatusCommentsState> {

    static contextType = AppContext;
    context!: IReactContext<IAppState>;

    state = {
        comments: this.props.comments,
        ledgerIds: this.props.ledgerIds,
        groupStatusChange: this.props.groupStatusChange
    }

    constructor(props: IStatusCommentsModalProps){
        super(props);
    }
    
    handleOk = () => {
        this.props.submitModal(this.state.comments);
    }

    closeModal = () => {
        this.setState({comments: ""});
        this.props.closeModal();
    }

    render() {
        const { statusCommentsModal } = this.context.state;

        return (
            ReactDOM.createPortal(
            <div >
                { this.state.ledgerIds == statusCommentsModal.associatedLedgerIds && this.state.groupStatusChange == statusCommentsModal.groupStatusChange &&
                <Dialog title={statusCommentsModal.title} onClose={this.closeModal} 
                    width={500}
                    height={300}
                >
                    <div>
                        <h5 style={{color: 'black'}}>{statusCommentsModal.commentsTitle}</h5>
                        <textarea   
                                    value={this.state.comments}
                                    onChange={e => this.setState({  comments: e.target.value })}
                                    rows={5}
                                    cols={50}
                                    style={statusCommentsModal.hasError ? { borderColor: 'red', height: 120, width: '100%'} : { borderColor: 'rgb(169,169,169)', height: 120, width: '100%'} }
                                />
                        { statusCommentsModal.hasError && 
                         <div style={{color: 'red'}}>
                             {statusCommentsModal.commentsError}
                         </div>
                        }
                    </div>
                    <DialogActionsBar>
                        <button className="k-button" onClick={this.closeModal}>{ statusCommentsModal.cancelButtonText ?? 'Cancel'}</button>
                        <button className="k-button" onClick={this.handleOk}>{ statusCommentsModal.confirmButtonText ?? 'Submit'}</button>
                    </DialogActionsBar>
                </Dialog>  
                }  
            </div>, document.body)
        )
    }
}