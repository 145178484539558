import * as React from 'react';
import { Navbar, Row, Col, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap'
import './Header.scss';

export const Header: React.FunctionComponent = () => {

    return (
        <Navbar id="header-component" bg='light' variant='light' expand='lg'>
            <Navbar.Brand>
                <LinkContainer to='/'>
                    <Nav.Item className='cursor-pointer'>
                        <img
                            src='../../../../static/phxlogo.png'
                            width='150'
                            height='47'
                            className='d-inline-block align-top'
                            alt='Phoenix Loss'
                        />
                    </Nav.Item>
                </LinkContainer>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls='basic-navbar-nav' />
            <Navbar.Collapse id='basic-navbar-nav'>
                <Nav className='mr-auto'>
                    <LinkContainer to='/' className='nav-link cursor-pointer'>
                        <Nav.Item>Home</Nav.Item>
                    </LinkContainer>
                    <LinkContainer to='/trackers/' className='nav-link cursor-pointer'>
                        <Nav.Item>Trackers</Nav.Item>
                    </LinkContainer>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}