/// <reference path="../../../common-types.d.ts" />
/// <reference path="../../../interfaces.d.ts" />
import * as React from 'react';
import { AppContext } from '../../AppStateProvider';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import ReactDOM = require('react-dom');

declare interface IConfirmModalProps {
    confirmModal: (ledgerStatusId: number, comments: string) => void;
    closeModal: () => void;
    ledgerIds: string;
    groupStatusChange: boolean;
}

export class ConfirmModal extends React.Component<IConfirmModalProps> {
    static contextType = AppContext;
    context!: IReactContext<IAppState>;

    handleOk = async () => {
        const { confirmModal } = this.context.state;

        var comments = confirmModal.comments;
        var ledgerStatusId = confirmModal.ledgerStatusId;
        
        await this.props.confirmModal(ledgerStatusId, comments);
    }

    closeModal = () => {
        this.props.closeModal();
    }

    render() {
        const { confirmModal } = this.context.state;
        
        return (
            ReactDOM.createPortal(
            <div >
                { confirmModal.open && this.props.ledgerIds == confirmModal.associatedLedgerIds && this.props.groupStatusChange == confirmModal.groupStatusChange &&
                <Dialog title={confirmModal.title} onClose={this.closeModal}
                    className="confirmModal"
                >
                    <div>
                        { confirmModal.message }
                    </div>
                    <DialogActionsBar>
                        <button className="k-button" onClick={this.closeModal}>{ confirmModal.cancelButtonText ?? 'Cancel'}</button>
                        <button className="k-button" onClick={this.handleOk}>{ confirmModal.confirmButtonText ?? 'ok'}</button>
                    </DialogActionsBar>
                </Dialog> }     
            </div>, document.body)
        )
    }
}