/// <reference path="../../../../common-types.d.ts" />
/// <reference path="../../../../interfaces.d.ts" />
/// <reference path="../../../../types.d.ts" />
/// <reference path="../../../../data/data-models.d.ts" />
import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { AppContext } from '../../../AppStateProvider';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

export class ClaimDetail extends React.Component {
    static contextType = AppContext;
    context!: IReactContext<IAppState>;

    closeModal = () => {
        this.context.set({
            claimDetailModal: { open: false, item: undefined }
        })
    }

    header = (text) => {
        return (<label style={{ fontWeight: 'bold', fontSize: '16px' }}>{text}</label>)
    }

    cell = (label, value) => {
        return (
            <span>
                <label>{label}:&nbsp;</label>
                <label style={{ fontWeight: 'bold' }}>{value}</label>
            </span>
        )
    }

    viewClaim = () => {
        const { claimDetailModal } = this.context.state;
        window.open(`${appSettings.CtsUrl}/ClaimManager.aspx?claimid=${claimDetailModal.item.claimId}`, 'cts');
    }

    formatDateTime = (date) => {
        if (!date) {
            return ''
        }
        const d = new Date(date);
        const day = d.getDate()
        const month = d.getMonth() + 1
        const year = d.getFullYear()
        const hours = d.getHours() == 0 ? 12 : (d.getHours() > 12 ? d.getHours() - 12 : d.getHours())
        const minutes = d.getMinutes().toString().padStart(2, "0").slice(-2);
        const ampm = d.getHours() >= 12 ? 'pm' : 'am'
        
        return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`
    }

    formatMinutes = (num) => { 
        var hours = Math.floor(num / 60).toString().padStart(2, "0").slice(-2);  
        var minutes = (num % 60).toString().padStart(2, "0").slice(-2);
        return `${hours}:${minutes}`;
    }

    render() {
        const { claimDetailModal } = this.context.state;
        return (
            <div >
                { claimDetailModal.open &&
                    <Dialog title={"Claim #" + claimDetailModal.item.claimNumber} onClose={this.closeModal}>
                        <div style={{ width: '80vw', color: 'black' }}>
                            <Row>
                                <Col sm={12}>{this.header('Field Investigator')}</Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    { this.cell('Is Tele-Investigation', claimDetailModal.item.isTeleInvestigation ? 'Yes' : 'No') }
                                </Col>
                                <Col sm={3}>
                                    { this.cell('Arrived', this.formatDateTime(claimDetailModal.item.damageInvestigatorArrivalTimestamp)) }
                                </Col>
                                <Col sm={3}>
                                    { this.cell('Total Drive Time (HH:MM)', this.formatMinutes(claimDetailModal.item.investigatorDrivetime)) }
                                </Col>
                                <Col sm={3}>
                                    { this.cell('Other Time (HH:MM)', this.formatMinutes(claimDetailModal.item.investigatorAdminTime)) }
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    { this.cell('Contacted', this.formatDateTime(claimDetailModal.item.damageInvestigatorContactTimestamp)) }
                                </Col>
                                <Col sm={3}>
                                    { this.cell('Mileage to and from', claimDetailModal.item.damageInvestigatorMileage) }
                                </Col>
                                <Col sm={3}>
                                    { this.cell('Time on Site (HH:MM)', this.formatMinutes(claimDetailModal.item.investigatorTimeOnSite)) }
                                </Col>
                                <Col sm={3}>
                                    { this.cell('Pay Mileage Reimbursement', claimDetailModal.item.payMileageReimbursement ? 'Yes' : 'No') }
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={9}>
                                </Col>
                                <Col sm={3}>
                                    { this.cell('Amount Reimbursed', "$" + claimDetailModal.item.amountReimbursed.toLocaleString(navigator.language, {minimumFractionDigits: 2})) }
                                </Col>
                            </Row>

                            <Row style={{ marginTop: '14px' }}>
                                <Col sm={12}>{this.header('Damage Details')}</Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    { this.cell('Client', claimDetailModal.item.clientName) }
                                </Col>
                                <Col sm={3}>
                                    { this.cell('Address 1', claimDetailModal.item.damageAddress1) }
                                </Col>
                                <Col sm={3}>
                                    { this.cell('City', claimDetailModal.item.damageCity) }
                                </Col>
                                <Col sm={3}>
                                    { this.cell('State', claimDetailModal.item.damageState) }
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    { this.cell('Damager Name', claimDetailModal.item.damagerName) }
                                </Col>
                                <Col sm={3}>
                                    { this.cell('Address 2', claimDetailModal.item.damageAddress2) }
                                </Col>
                                <Col sm={3}>
                                    { this.cell('County', claimDetailModal.item.damageCounty) }
                                </Col>
                                <Col sm={3}>
                                    { this.cell('Postal Code', claimDetailModal.item.damagePostalcode) }
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    { this.cell('Short Description', claimDetailModal.item.detailsofDamageLoss)}
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '14px' }}>
                                <Col sm={12}>{this.header('Investigation Details')}</Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    { this.cell('Hit Kit Used', claimDetailModal.item.hitKit.toString()) }
                                </Col>
                                <Col sm={3}>
                                    { this.cell('Encore Picture Count', claimDetailModal.item.encorePictureCount) }
                                </Col>
                                <Col sm={3}>
                                    { this.cell('Encore Order Id', claimDetailModal.item.encoreOrderId) }
                                </Col>
                                <Col sm={3}>
                                    { this.cell('Summary word Count', claimDetailModal.item.investigationSummaryWordCount) }
                                </Col>
                            </Row>
                        </div>
                        <DialogActionsBar>
                            <button className="k-button" onClick={this.closeModal}>Close</button>
                            <button className="k-button" onClick={this.viewClaim}>View Claim in CTS</button>
                        </DialogActionsBar>
                    </Dialog>
                }     
            </div>
        )
    }
}