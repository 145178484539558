import * as React from 'react';
import { mergeWith, isArray, cloneDeep } from 'lodash';

export const update = (state: DeepPartial<any>) => void (0);
export const reset = () => void (0);
export const request = (url: string, options: IFetchOptions, requestOptions?: IRequestOptions) => new Promise<Response>(r => r());

enum UpdateType {
    Set = "Set",
    Reset = "Reset"
}

interface ITrackableRequest {
    url: string;
    options: IFetchOptions;
    id: string
}

interface ITrackableRequestMap {
    [name: string]: ITrackableRequest[];
}

const requests: ITrackableRequestMap = {};

export abstract class StateProvider<T> extends React.Component implements IReactContext<T> {

    private appContext: React.Context<IReactContext<T>>;
    state: T;
    initialState!: T;
    debugMode!: boolean;

    constructor(props: any, defaultContext: React.Context<IReactContext<T>>, defaultState: T, debugMode: boolean) {
        super(props); // need to pass the same props that constructor passes, otherwise we get an error

        this.debugMode = debugMode;
        this.initialState = cloneDeep(defaultState);
        this.state = defaultState;
        this.appContext = defaultContext;
    }

    set = (appState: DeepPartial<T>, actionName: string = "") => {
        return this.updateState(appState, UpdateType.Set, actionName);
    }

    reset = (actionName: string = "") => {
        return this.updateState(this.initialState, UpdateType.Reset, actionName);
    }

    fetch = async (url: string, options: IFetchOptions, requestOptions?: IRequestOptions) => {

        let requestGroup = "";
        let wait = 0;

        if (!this.debugMode) {
            return window.fetch(url, options as any);
        }

        if (requestOptions) {
            requestGroup = requestOptions.requestGroup || "";
            wait = requestOptions.waitForNextRequestMilliseconds || 0;
        }

        const id = this.generateUUID();

        if (!requests[requestGroup]) {
            requests[requestGroup] = [];
        }

        if (requests[requestGroup].length === 0) {
            this.onRequestGroupStarted(requestGroup);
        }

        requests[requestGroup].push({ url, options, id });

        try {
            const response = await window.fetch(url, options as any);

            this.processApiResponseComplete(requestGroup, id, wait);
            return response;
        } catch (e) {
            this.processApiResponseComplete(requestGroup, id, wait);
            throw e;
        }
    };

    private processApiResponseComplete = (requestGroup: string, id: string, wait: number) => {
        const index = requests[requestGroup].findIndex(w => w.id == id);
        requests[requestGroup].splice(index, 1);

        if (requests[requestGroup].length === 0) {

            if (wait > 0) {
                setTimeout(() => {
                    if (requests[requestGroup].length === 0) {
                        // all requests finished
                        this.onRequestGroupComplete(requestGroup);
                    }
                }, wait);
            } else {
                this.onRequestGroupComplete(requestGroup);
            }
        }
    }

    onRequestGroupComplete = (requestGroup: string) => {
        // noop
    }

    onRequestGroupStarted = (requestGroup: string) => {
        // noop
    }

    private generateUUID = () => {
        // https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
        let d = new Date().getTime();//Timestamp
        let d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            let r = Math.random() * 16;//random number between 0 and 16
            if (d > 0) {//Use timestamp until depleted
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    private getBrowserSplitValue = () => {
        if (this.isFireFox()) {
            return "@";
        }

        return "at ";
    }

    private getBrowserCallerLocation = () => {
        if (this.isFireFox()) {
            return 2;
        }

        return 3;
    }

    private isFireFox = () => {
        return window?.navigator?.userAgent != null && window.navigator.userAgent.toUpperCase().indexOf("FIREFOX") > -1;
    }

    private cleanTrace = (trace: string) => {
        if (this.isFireFox()) {
            return trace.replace(/(http:.*)((\d){0,}:(\d){0,})/, "").replace("\r", "").replace("\n", "").replace("/<", "");
        }

        return trace.replace(/\s\(http.*\)/, "").replace("\r", "").replace("\n", "");
    }

    private updateState = (newAppState: DeepPartial<T>, type: UpdateType, actionName: string) => {

        const newState = mergeWith(cloneDeep(this.state), cloneDeep(newAppState), (oldValue: any, newValue: any) => {
            if (isArray(oldValue) || isArray(newValue)) {
                return newValue;
            }
        });

        // if there are nested objects on state, we need to merge those nested objects
        // otherwise they will be overwritten the way setState works
        if (this.debugMode === true) {
            let caller = actionName;

            if (!caller) {
                const splitAt = this.getBrowserSplitValue();
                const stackTrace = new Error().stack || "";
                const trace = stackTrace.split(splitAt);

                if (trace.length === 0) {
                    caller = "<NOT FOUND>";
                } else {
                    let index = trace.findIndex(w => w.indexOf("StateProvider.reset") > -1 || w.indexOf("StateProvider.set") > -1);

                    if (index === -1) {
                        index = this.getBrowserCallerLocation(); // should be the default location
                    } else {
                        index++;
                    }
    
                    caller = this.cleanTrace(trace[index]);
                }
            }

            console.info(`%c--- ${type} State Starting ---`, 'color:#a81bb0;');
            console.info("%cOld State", 'color:lightblue;', this.state);
            console.info("%cPayload", 'color:lightblue;', newAppState);
            console.info("%cAction", 'color:lightblue;', caller);

            // Set State
            this.setState(newState);

            console.info("%cNew State", 'color:lightblue;', newState);
            console.info(`%c--- ${type} State Ending ---`, 'color:#a81bb0;');
        } else {
            this.setState(newState);
        }
    }

    render() {
        const context = this.appContext;
        return (<context.Provider value={{ fetch: this.fetch, state: this.state, set: this.set, reset: this.reset }}>{this.props.children}</context.Provider>);
    }
}