import * as React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';

interface IDropdownFilterCellProps {
    value: string;
    onChange: (payload: { value: string, operator: string, syntheticEvent: any }) => void;
    data: INameIdPair[];
    defaultItem: any;
    disabled?: boolean;
}

export class GridDropdownFilterCell extends React.Component<IDropdownFilterCellProps> {

    hasValue = (value: any) => Boolean(value && value !== this.props.defaultItem);

    onChange = (event: any) => {
        const hasValue = this.hasValue(event.target.value);
        this.props.onChange({
            value: hasValue ? event.target.value : '',
            operator: hasValue ? 'eq' : '',
            syntheticEvent: "one"
        });
    }

    onClearButtonClick = (event: any) => {
        event.preventDefault();
        this.props.onChange({
            value: '',
            operator: '',
            syntheticEvent: "clear"
        });
    }

    isDisabled = () => {
        return Boolean(this.props.disabled);
    }

    render() {
        return (
            <div className="k-filtercell">
                <DropDownList
                    data={this.props.data}
                    dataItemKey="id"
                    textField="name"
                    onChange={this.onChange}
                    value={this.props.value || this.props.defaultItem}
                    defaultItem={this.props.defaultItem}
                    disabled={this.isDisabled()}
                />
                <button
                    className="k-button k-button-icon k-clear-button-visible"
                    title="Clear"
                    disabled={!this.hasValue(this.props.value)}
                    onClick={this.onClearButtonClick}
                >
                    <span className="k-icon k-i-filter-clear" />
                </button>
            </div>
        );
    }
}