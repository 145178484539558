/// <reference path="../common-types.d.ts" />
/// <reference path="./data-models.d.ts" />
/// <reference path="../interfaces.d.ts" />
import axios, { AxiosResponse } from 'axios'
import { TrackerLedgerStatus } from '../common/enum/TrackerLedgerStatus'

export const getAllTrackersByStatusIds = async (statusIds: TrackerLedgerStatus[], filterState: IFilterState, 
    viewAllTrackers: boolean = false, closeDateBegin: Date | null, closeDateEnd: Date | null): Promise<AxiosResponse> => {
    const ids = statusIds.map(w => `statusIds=${w}`).join('&');
    const formData = new FormData();
    formData.append('skip', JSON.stringify(filterState.skip));
    formData.append('take', JSON.stringify(filterState.take));
    formData.append('sort', JSON.stringify(filterState.sort));
    formData.append('filter', JSON.stringify(filterState.filter));
    formData.append('closeDateBegin', JSON.stringify(closeDateBegin));
    formData.append('closeDateEnd', JSON.stringify(closeDateEnd));

    return await axios.post(`${appSettings.ApiUrl}/api/expensegroup/status?${ids}&viewAllTrackers=${viewAllTrackers}`, formData, { 
        withCredentials: true, 
        headers: {
            'Content-Type': 'multipart/form-data' 
        } 
    });
}

export const getExcelFileExport = async (statusIds: TrackerLedgerStatus[], filterState: IFilterState, 
    viewAllTrackers: boolean = false, closeDateBegin: Date | null, closeDateEnd: Date | null): Promise<AxiosResponse> => {

    const ids = statusIds.map(w => `statusIds=${w}`).join('&');
    const formData = new FormData();
    formData.append('sort', JSON.stringify(filterState.sort));
    formData.append('filter', JSON.stringify(filterState.filter));
    formData.append('closeDateBegin', JSON.stringify(closeDateBegin));
    formData.append('closeDateEnd', JSON.stringify(closeDateEnd));

    return await axios.post(`${appSettings.ApiUrl}/api/expensegroup/export?${ids}&viewAllTrackers=${viewAllTrackers}`, formData, { 
        withCredentials: true, 
        responseType: 'blob'
    });
}
